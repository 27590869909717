import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { MdError } from 'react-icons/md';

import { useContractFlags } from '~/hooks';
import { ContractPaymentItems } from '~/common';

import { ContractPaymentsButtons } from '../ContractPaymentsButtons/ContractPaymentsButtons';

import messages from './messages';

export type ContractPendingPaymentsProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const ContractPendingPayments = ({
  contract,
  payments,
}: ContractPendingPaymentsProps) => {
  const { isSignedOrPendingSignature } = useContractFlags();

  if (contract.status === 'NO_SHOW') {
    return (
      <Card data-testid="PENDING_PAYMENTS_CARD" className="py-8 px-14 mb-4">
        <h2 className="text-lg text-neutral-800">
          <div className="flex justify-between gap-8">
            <FormattedMessage {...messages.pending} />
            <span>-</span>
          </div>
        </h2>
      </Card>
    );
  }

  const pendingPayments = payments.find(
    (payment) => payment.status === 'PENDING'
  );

  const getPendingPaymentsTitle = () => {
    if (pendingPayments) {
      return (
        <h2 className="text-lg text-neutral-800 mb-8">
          <div className="flex items-center justify-left gap-2">
            <FormattedMessage {...messages.pending} />
            {contract.pendingAmount.value > 0 && (
              <MdError className="text-warning-500 text-xl" />
            )}
          </div>
        </h2>
      );
    }

    return (
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg text-neutral-800">
          <FormattedMessage {...messages.pending} />
        </h2>
        <span>-</span>
      </div>
    );
  };

  return (
    <Card data-testid="PENDING_PAYMENTS_CARD" className="py-8 px-14 mb-4">
      {getPendingPaymentsTitle()}
      <div>
        {pendingPayments && (
          <>
            <ContractPaymentItems.Vehicle
              className="mb-2"
              payment={pendingPayments}
            />
            <ContractPaymentItems.Coverage
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayments}
            />
            <ContractPaymentItems.Accessories
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayments}
            />
            <ContractPaymentItems.Penalties
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayments}
            />
            <ContractPaymentItems.ValueAdjustment
              contractId={contract.contractId}
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayments}
            />
            <ContractPaymentItems.Total
              className="mb-10 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayments}
            />
          </>
        )}
        <ContractPaymentsButtons
          hasPendingPayments={!!pendingPayments}
          disabled={isSignedOrPendingSignature}
          contract={contract}
        />
      </div>
    </Card>
  );
};
