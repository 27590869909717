import { Model } from '@cooltra/api';
import { Tooltip } from '@cooltra/ui';
import { differenceInMinutes } from 'date-fns';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { RentalTimelineItemReduced } from '~/libs/reduce-rental-timeline';
import { calculateTimeDifference } from '~/libs/time-difference';

import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';
import { RentalTimelineTick } from '../RentalTimelineTick';

import messages from './messages';
import { RentalVehicleStatus } from './Status';

export type VehicleStatusItemProps = Pick<
  RentalTimelineItemReduced,
  'status' | 'occurredOn'
> & {
  isABike: boolean;
  isLast: boolean;
  rentalActiveOccurredOn?: string;
  model: Model;
};

export const VehicleStatusItem = ({
  occurredOn,
  status,
  isABike,
  isLast,
  rentalActiveOccurredOn,
  model,
}: VehicleStatusItemProps) => {
  if (!status) {
    return <></>;
  }

  const getSinceRentalActiveContent = () => {
    if (!rentalActiveOccurredOn) {
      return <></>;
    }

    const ONE_HOUR_IN_MINUTES = 60;
    const timeDifferenceWithRentalActive = calculateTimeDifference(
      new Date(rentalActiveOccurredOn),
      new Date(occurredOn)
    );
    const timeDifferenceWithRentalActiveInMinutes = differenceInMinutes(
      new Date(occurredOn),
      new Date(rentalActiveOccurredOn)
    );

    if (!timeDifferenceWithRentalActive) {
      return <></>;
    }

    return (
      <div className="px-4 py-3 text-neutral-500">
        {timeDifferenceWithRentalActiveInMinutes >= ONE_HOUR_IN_MINUTES ? (
          <FormattedMessage
            {...messages.sinceRentalActiveWithMinutes}
            values={{
              rentalActiveOccurredOn: timeDifferenceWithRentalActive,
              rentalActiveOccurredOnInMinutes:
                timeDifferenceWithRentalActiveInMinutes,
            }}
          />
        ) : (
          <FormattedMessage
            {...messages.sinceRentalActive}
            values={{
              rentalActiveOccurredOn: timeDifferenceWithRentalActive,
            }}
          />
        )}
      </div>
    );
  };

  return (
    <RentalTimelineRowLayout
      data-testid="VEHICLE_STATUS_ITEM"
      className="items-center mb-8"
      isLast={isLast}
      timeline={<RentalTimelineTick />}
      content={
        <div className="flex gap-2 items-center">
          <span className="text-xs text-neutral-300">
            <Tooltip
              button={
                <span>
                  <FormattedTime
                    value={occurredOn}
                    hour="2-digit"
                    hourCycle="h23"
                    minute="2-digit"
                  />
                </span>
              }
              content={getSinceRentalActiveContent()}
            />
          </span>
          <div className="py-4 rounded-md border border-neutral-200 w-full">
            {isABike ? (
              <div className="flex w-full px-4">
                <div className="flex-1">
                  <RentalVehicleStatus.Lock lock={status.lock} />
                </div>
                <div className="flex-1 flex justify-center">
                  <RentalVehicleStatus.Power off={status.powerOff} />
                </div>
                <div className="flex-1 flex justify-end">
                  <RentalVehicleStatus.Battery
                    batteryCharge={status.batteryCharge}
                    model={model}
                  />
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-5 w-full px-2">
                <div className="flex justify-start">
                  <RentalVehicleStatus.Lock lock={status.lock} />
                </div>
                <div className="flex justify-center">
                  <RentalVehicleStatus.CentralStand
                    down={status.centralStandDown}
                  />
                </div>
                <div className="flex justify-center">
                  <RentalVehicleStatus.TopCase closed={status.topCaseClosed} />
                </div>
                <div className="flex justify-center">
                  <RentalVehicleStatus.Power off={status.powerOff} />
                </div>
                <div className="flex justify-end">
                  <RentalVehicleStatus.Battery
                    batteryCharge={status.batteryCharge}
                    model={model}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};
