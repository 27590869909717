import { defineMessages } from 'react-intl';

export default defineMessages({
  release: {
    defaultMessage: 'Release',
  },
  markAsReleased: {
    defaultMessage: 'Mark as released',
  },
  depositHasBeenReleased: {
    defaultMessage: 'Deposit has been released successfully',
  },
  areYourSureToReleaseTPVDeposit: {
    defaultMessage:
      'Do you confirm that you have released the deposit using the TPV?',
  },
  tpvOperationId: {
    defaultMessage: 'TPV Operation ID: {operationId}',
  },
  cardLastFourDigits: {
    defaultMessage: 'Card: **** {lastFourDigits}',
  },
  areYourSureToReleaseStripeDeposit: {
    defaultMessage:
      'Are you sure you want to proceed with the release to the saved credit card?',
  },
  confirmRelease: {
    defaultMessage: 'Confirm release {amount}',
  },
  tpvConfirmRelease: {
    defaultMessage: "Yes, I've released {amount}",
  },
});
