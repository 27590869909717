import { defineMessages } from 'react-intl';

export default defineMessages({
  sign: {
    defaultMessage: 'Sign',
  },
  successfulContractSigned: {
    defaultMessage: 'Contract signed successfully',
  },
  vehicleAssignedToAnotherActiveContract: {
    defaultMessage: 'The vehicle is assigned to another active contract',
  },
});
