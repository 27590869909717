import { PropsWithChildren, useState } from 'react';
import { Modal } from '@cooltra/ui';

import { QRPayment } from '~/common';

import { OpenModalOptions, QRPaymentContext } from './QRPaymentContext';

export const QRPaymentProvider = ({ children }: PropsWithChildren) => {
  const [{ isOpen, id, isDeposit }, setState] = useState({
    isDeposit: false,
    isOpen: false,
    id: '',
  });

  const openModal = ({ id, isDeposit = false }: OpenModalOptions) =>
    setState({
      isDeposit,
      id,
      isOpen: true,
    });

  const closeModal = () => setState((state) => ({ ...state, isOpen: false }));

  const onAfterClose = () => setState((state) => ({ ...state, id: '' }));

  return (
    <QRPaymentContext.Provider value={{ openModal }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        onAfterClose={onAfterClose}
      >
        <QRPayment id={id} isDeposit={isDeposit} className="px-20 pb-12" />
      </Modal>
      {children}
    </QRPaymentContext.Provider>
  );
};
