import { FormattedDate, FormattedMessage } from 'react-intl';
import { ContractDeposit, ContractStatus } from '@cooltra/station-based-api';
import { isPast } from 'date-fns';

import {
  Amount,
  DateTime,
  ReleaseDeposit,
  StripeCustomerLink,
  TextSeparator,
} from '~/common';
import { useContractFlags } from '~/hooks';
import { getContractDepositMethodMessage } from '~/utils/contract';

import messages from './messages';

export type WithheldDepositProps = {
  contractId: string;
  contractStatus: ContractStatus;
  deposit: ContractDeposit;
};

export const WithheldDeposit = ({
  contractId,
  contractStatus,
  deposit,
}: WithheldDepositProps) => {
  const {
    withheldAt,
    amount,
    operationNumber,
    lastFourDigits,
    method,
    withheldValidUntil,
    isCharge,
    releasedAt,
    paymentMethodDetails,
  } = deposit;

  const {
    hasDepositBeenReleasedAutomatically,
    hasDepositBeenWithheld,
    isSignedOrPendingSignature,
  } = useContractFlags();

  const expirationDate = releasedAt || withheldValidUntil;
  const retentionHasExpired = withheldValidUntil && isPast(withheldValidUntil);

  const displayReleaseButton =
    hasDepositBeenWithheld &&
    !retentionHasExpired &&
    (contractStatus === 'DRAFT' ||
      contractStatus === 'BOOKED' ||
      contractStatus === 'ACTIVE' ||
      contractStatus === 'CLOSED');

  return (
    <div className="flex justify-between items-end">
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          {isCharge ? (
            <FormattedMessage {...messages.depositCharged} />
          ) : (
            <FormattedMessage {...messages.depositRetained} />
          )}
        </span>
        {method === 'TPV' && hasDepositBeenReleasedAutomatically && (
          <span className="text-warning-600">
            <FormattedMessage {...messages.depositExpired} />
          </span>
        )}
        <div className="flex items-center gap-3 text-neutral-500">
          <DateTime
            date={
              hasDepositBeenReleasedAutomatically ? expirationDate : withheldAt
            }
            dateClassName="text-neutral-500 text-base"
            timeClassName="text-neutral-500 text-base"
          />
          <TextSeparator />
          {method === 'TPV' && (
            <>
              <FormattedMessage
                {...getContractDepositMethodMessage(method)}
                tagName="span"
              />
              <TextSeparator />
              <span>#{operationNumber}</span>
              <TextSeparator />
              <span>#{lastFourDigits}</span>
            </>
          )}
          {method === 'BOOKING_CARD' && paymentMethodDetails && (
            <StripeCustomerLink paymentMethod={paymentMethodDetails} />
          )}
          {method !== 'BOOKING_CARD' && method !== 'TPV' && (
            <FormattedMessage
              {...getContractDepositMethodMessage(method)}
              tagName="span"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3 items-end">
        <div className="flex items-center gap-3">
          {displayReleaseButton && (
            <ReleaseDeposit
              deposit={deposit}
              disabled={isSignedOrPendingSignature}
              contractId={contractId}
            />
          )}
          <Amount
            className="font-semibold text-xl text-neutral-800"
            {...amount}
          />
        </div>
        <span className="block text-neutral-500">
          {!hasDepositBeenReleasedAutomatically && withheldValidUntil && (
            <FormattedMessage
              {...messages.retentionValidUntil}
              tagName="span"
              values={{
                date: (
                  <FormattedDate
                    value={new Date(withheldValidUntil)}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                ),
              }}
            />
          )}
        </span>
      </div>
    </div>
  );
};
