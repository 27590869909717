import { useServicePointsQuery } from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { MdAirportShuttle, MdLocationOn, MdStorefront } from 'react-icons/md';

import messages from './messages';

export type VehicleServicePointProps = {
  servicePointId: string;
  inTransit: boolean;
};

export const VehicleServicePoint = ({
  servicePointId,
  inTransit,
}: VehicleServicePointProps) => {
  const { data: servicePoints } = useServicePointsQuery(
    {},
    {
      enabled: false,
    }
  );
  const servicePoint = servicePoints?.find(({ id }) => id === servicePointId);

  if (!servicePoint) {
    return null;
  }

  const { type, name } = servicePoint;

  return (
    <div>
      {inTransit ? (
        <div className="flex gap-1.5">
          <MdAirportShuttle className="text-primary-300" />
          <div className="text-primary-700">
            <span className="text-xs block">
              <FormattedMessage {...messages.inTransit} />
            </span>
            <span className="text-xs block">{name}</span>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-1.5">
          {type === 'DELIVERY_POINT' ? (
            <MdLocationOn className="text-neutral-300" />
          ) : (
            <MdStorefront className="text-neutral-300" />
          )}
          <span className="text-neutral-800 text-xs">{name}</span>
        </div>
      )}
    </div>
  );
};
