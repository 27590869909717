import { FormattedMessage } from 'react-intl';
import {
  useContractQuery,
  useRemoveSubscriptionVehicleTypesMutation,
} from '@cooltra/station-based-api';
import { Button, Card } from '@cooltra/ui';
import { Money } from '@cooltra/utils';
import { useToggle } from '@cooltra/hooks';

import { Amount, AssignLicensePlate, RemoveVehicleTypeButton } from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type SelectedVehicleTypeCardProps = {
  name: string;
  contractId: string;
  vehicleTypeName: string;
  vehicleTypeImage: string;
  price: Money;
  isRemoveButtonDisabled: boolean;
};

export const SelectedVehicleTypeCard = ({
  name,
  contractId,
  vehicleTypeName,
  vehicleTypeImage,
  price,
  isRemoveButtonDisabled,
}: SelectedVehicleTypeCardProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { data: contract } = useContractQuery(contractId, {
    enabled: false,
  });
  const { mutateAsync } = useRemoveSubscriptionVehicleTypesMutation(contractId);

  const { isSignedOrPendingSignature, isActive } =
    useSubscriptionContractFlags();

  const onRemove = () => mutateAsync();

  if (!contract || !contract.vehicle) {
    return null;
  }

  const {
    vehicle: { vehicleTypeId, assignedVehicle },
  } = contract;

  return (
    <>
      <AssignLicensePlate
        isOpen={isOpen}
        contractId={contractId}
        vehicleTypeId={vehicleTypeId}
        vehicleTypeName={vehicleTypeName}
        onClose={toggleOff}
      />
      <Card data-testid={`VEHICLE_${name}`} className="bg-neutral-0 p-8">
        <div>
          <h2 className="mb-4 text-lg font-semibold text-neutral-800">
            {assignedVehicle?.licensePlate
              ? `${assignedVehicle.licensePlate} - ${vehicleTypeName}`
              : `${vehicleTypeName}`}
          </h2>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center shrink-0">
            <img className="w-24 mr-6" alt={name} src={vehicleTypeImage} />
            <div className="flex flex-col">
              <span className="mb-2 text-3xl font-semibold text-neutral-800">
                <Amount {...price} />
              </span>
              <span className="text-base font-medium text-neutral-600">
                <FormattedMessage {...messages.monthlyPrice} />
              </span>
            </div>
          </div>
          <div className="flex items-end gap-2">
            <RemoveVehicleTypeButton
              onRemove={onRemove}
              disabled={isRemoveButtonDisabled}
            />
            {!isActive && assignedVehicle?.licensePlate && (
              <Button onClick={toggleOn} disabled={isSignedOrPendingSignature}>
                <FormattedMessage {...messages.changeLicensePlate} />
              </Button>
            )}
            {!assignedVehicle?.licensePlate && (
              <Button onClick={toggleOn}>
                <FormattedMessage {...messages.assignLicensePlate} />
              </Button>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};
