import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractDeposit,
  useCancelContractPendingDepositMutation,
  useContractQuery,
} from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';
import { MdQrCode } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';

import { useQRPayment } from '~/libs/qr-payment';
import { useNotification } from '~/hooks';

import { TextSeparator } from '../../../TextSeparator/TextSeparator';
import { ConfirmModal } from '../../../ConfirmModal/ConfirmModal';
import { Amount } from '../../../Amount/Amount';

import messages from './messages';

export type PendingDepositProps = {
  contractId: string;
  deposit: ContractDeposit;
};

export const PendingDeposit = ({
  contractId,
  deposit: { id, amount },
}: PendingDepositProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);

  const { mutateAsync, isPending } = useCancelContractPendingDepositMutation(
    contractId,
    id
  );
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const cancelPendingDeposit = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  const { refetch, isRefetching } = useContractQuery(contractId, {
    enabled: false,
  });
  const { openModal } = useQRPayment();
  const verifyDeposit = () => refetch();
  const openQR = () => openModal({ id, isDeposit: true });

  return (
    <>
      <div className="flex justify-between items-end">
        <div className="flex flex-col gap-3">
          <span className="font-medium text-lg">
            <FormattedMessage {...messages.pendingDeposit} />
          </span>
          <div className="flex items-center gap-3 text-neutral-500">
            <FormattedMessage {...messages.waitingStripeWithhold} />
            <TextSeparator />
            <Button
              onClick={openQR}
              emphasis="low"
              size="sm"
              leadingIcon={<MdQrCode />}
            >
              <FormattedMessage {...messages.showStripeQR} />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-3 items-end">
          <Amount
            className="font-semibold text-xl text-neutral-800"
            {...amount}
          />
          <div className="flex items-center gap-3">
            <Button
              onClick={toggleOn}
              emphasis="low"
              size="sm"
              variant="danger"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button loading={isRefetching} size="sm" onClick={verifyDeposit}>
              <FormattedMessage {...messages.verify} />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        className="max-w-md min-w-xs pb-3"
        isOpen={isOpen}
        title={formatMessage(messages.cancelPendingDepositTitle)}
        content={
          <p className="px-12 mt-7 mb-9 text-neutral-700 text-md">
            <FormattedMessage {...messages.cancelPendingDepositContent} />
          </p>
        }
        confirmText={<FormattedMessage {...messages.cancelDeposit} />}
        onConfirm={cancelPendingDeposit}
        onClose={toggleOff}
        loading={isPending}
      />
    </>
  );
};
