import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingStripeWithhold: {
    defaultMessage: 'Pending charge (awaiting payment)',
  },
  pendingDeposit: {
    defaultMessage: 'Pending deposit',
  },
  showStripeQR: {
    defaultMessage: 'Show QR',
  },
  verify: {
    defaultMessage: 'Verify deposit',
  },
  success: {
    defaultMessage: 'Pending deposit has been cancelled successfully',
  },
  cancelDeposit: {
    defaultMessage: 'Cancel deposit',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  cancelPendingDepositTitle: {
    defaultMessage: 'Cancel pending deposit',
  },
  cancelPendingDepositContent: {
    defaultMessage: 'Are you sure you want to cancel the pending deposit?',
  },
});
