import { InputError } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';

import { AmountInputField, AmountInputFieldProps } from './AmountInputField';

export type FormAmountInputFieldProps = Omit<
  AmountInputFieldProps,
  | 'valid'
  | 'invalid'
  | 'onChange'
  | 'onBlur'
  | 'value'
  | 'error'
  | 'name'
  | 'defaultValue'
> & {
  name: string;
  allowNegative?: boolean;
};

export const FormAmountInputField = ({
  name,
  disabled,
  allowNegative = false,
  ...rest
}: FormAmountInputFieldProps) => {
  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, setValue, setTouched, error, touched } = useField(name);

  return (
    <AmountInputField
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={({ target: { value } }) => setValue(value.trim())}
      onBlur={() => setTouched(true)}
      value={value}
      name={name}
      disabled={isFormDisabled || isSubmitting || disabled}
      allowNegative={allowNegative}
      {...rest}
    />
  );
};
