import { Button } from '@cooltra/ui';
import { Contract, useCloseContractMutation } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { MdError } from 'react-icons/md';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks/useNotification';
import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type CloseSubscriptionButtonProps = {
  contract: Contract;
  className?: string;
};

export const CloseSubscriptionButton = ({
  contract,
  className,
}: CloseSubscriptionButtonProps) => {
  const { contractId, status } = contract;
  const intl = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync, isPending } = useCloseContractMutation(contractId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { hasPendingTicket, pendingChecksOut, hasDepositBeenCharged } =
    useSubscriptionContractFlags();

  const handleConfirm = () => {
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(intl.formatMessage(messages.success));
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  if (status !== 'ACTIVE') {
    return null;
  }

  if (!hasPermission('write:station_based_contracts')) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="CLOSE_CONTRACT_CONFIRM_MODAL"
        isOpen={isOpen}
        title={intl.formatMessage(
          messages.areYouSureYouWantToCloseThisContract
        )}
        confirmText={intl.formatMessage(messages.closeContract)}
        content={
          hasDepositBeenCharged && (
            <p className="flex items-center justify-center gap-1 text-warning-700 mb-4">
              <MdError className="text-warning-500 text-lg" />
              <FormattedMessage {...messages.depositPendingRelease} />
            </p>
          )
        }
        onClose={toggleOff}
        onConfirm={handleConfirm}
        loading={isPending}
      />
      <Button
        className={className}
        disabled={hasPendingTicket || pendingChecksOut}
        emphasis="high"
        onClick={toggleOn}
      >
        <FormattedMessage {...messages.closeContract} />
      </Button>
    </>
  );
};
