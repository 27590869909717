import { calculateTimeDifference } from '~/libs/time-difference';

import { WorkActionRowLayout } from '../WorkActionRowLayout/WorkActionRowLayout';

type NoWorkActionProps = {
  lastFinishedAt: string;
  nextCreatedAt: string;
};

export const NoWorkAction = ({
  lastFinishedAt,
  nextCreatedAt,
}: NoWorkActionProps) => {
  const getTimeDifference = () => {
    const timeDifference = calculateTimeDifference(
      new Date(lastFinishedAt),
      new Date(nextCreatedAt)
    );

    return (
      <span className="w-full text-center absolute text-xs text-neutral-400 bg-neutral-50 whitespace-nowrap">
        {timeDifference}
      </span>
    );
  };

  return (
    <div className="h-12 relative" data-testid="TIMELINE_FREE_GAP">
      <div className="h-full w-px absolute left-16 border-2 border-dashed border-neutral-50 ml-2" />
      <WorkActionRowLayout
        className="h-12 relative top-2"
        timeline={getTimeDifference()}
      />
    </div>
  );
};
