import { FormattedMessage, useIntl } from 'react-intl';
import {
  Contract,
  useContractAvailableAccessoriesQuery,
  usePatchContractAccessoriesMutation,
} from '@cooltra/station-based-api';
import { Button, Card, Divider } from '@cooltra/ui';
import { accessoryImages } from '@cooltra/utils';

import { useContractFlags, useNotification } from '~/hooks';
import { Amount } from '~/common';

import { ContractDriverDetailsNavigationTabs } from '../ContractDriverDetailsNavigationTabs/ContractDriverDetailsNavigationTabs';
import { ContractPendingSignAlert } from '../../ContractPendingSignAlert/ContractPendingSignAlert';

import messages from './messages';
import { ContractSecondDriverDetailsForm } from './ContractSecondDriverDetailsForm';

export type ContractSecondDriverDetailsProps = {
  contract: Contract;
};

export const ContractSecondDriverDetails = ({
  contract,
}: ContractSecondDriverDetailsProps) => {
  const { formatMessage } = useIntl();
  const { isSignedOrPendingSignature } = useContractFlags();
  const { contractId, accessories = [], status } = contract;
  const { data: accessoriesAvailable = [], isLoading } =
    useContractAvailableAccessoriesQuery(contractId);

  const { mutateAsync, isPending } =
    usePatchContractAccessoriesMutation(contractId);
  const { addErrorNotification } = useNotification();

  const secondDriver = accessories?.find(
    ({ name }) => name === 'CONDUCTOR_ADICIONAL'
  );

  const secondDriverExtra = accessoriesAvailable.find(
    ({ name }) => name === 'CONDUCTOR_ADICIONAL'
  );

  const handleAddSecondDriver = () => {
    mutateAsync({
      accessoryId: secondDriverExtra
        ? secondDriverExtra.accessoryId
        : secondDriver!.id,
      quantity: 1,
    }).catch(() => addErrorNotification());
  };

  const isEditingEnabled =
    !isSignedOrPendingSignature && (status === 'DRAFT' || status === 'BOOKED');

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <ContractDriverDetailsNavigationTabs contract={contract} />
      <ContractPendingSignAlert />
      {secondDriver ? (
        <ContractSecondDriverDetailsForm
          contract={contract}
          disabled={!isEditingEnabled}
        />
      ) : isEditingEnabled && secondDriverExtra ? (
        <Card className="px-12 py-10 flex flex-col gap-2 items-center max-w-lg mx-auto">
          <span className="font-medium text-neutral-700 text-center">
            <FormattedMessage {...messages.extraInfo} />
          </span>
          <Divider className="max-w-11 my-6" />
          <div className="flex items-center justify-center w-full">
            <img
              className="w-24 mr-6"
              src={accessoryImages.CONDUCTOR_ADICIONAL}
              alt={formatMessage(messages.additionalDriver)}
            />
            <div className="flex flex-col gap-3">
              <span className="font-medium text-neutral-600">
                <span className="text-3xl font-semibold text-neutral-800 mr-2">
                  <Amount {...secondDriverExtra?.price.daily} />
                </span>
                <FormattedMessage {...messages.dailyPrice} />
              </span>
              <span className="mb-2 font-medium text-neutral-600">
                <FormattedMessage
                  {...messages.totalPrice}
                  values={{
                    amount: <Amount {...secondDriverExtra?.price.total} />,
                  }}
                />
              </span>
            </div>
            <Button
              loading={isPending}
              disabled={isLoading}
              onClick={handleAddSecondDriver}
              className="ml-auto"
            >
              <FormattedMessage {...messages.addSecondDriver} />
            </Button>
          </div>
        </Card>
      ) : null}
    </div>
  );
};
