import { Button } from '@cooltra/ui';
import {
  Contract,
  useSignByEmailContractMutation,
} from '@cooltra/station-based-api';
import { MdSend } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';

import { useNotification } from '~/hooks/useNotification';
import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type SendContractByEmailButtonProps = {
  contract: Contract;
};

export const SendContractByEmailButton = ({
  contract,
}: SendContractByEmailButtonProps) => {
  const { contractId, status } = contract;
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const { user: operator } = useAuth0();
  const { mutateAsync, isPending } = useSignByEmailContractMutation(contractId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const {
    hasDepositBeenCharged,
    requiresDeposit,
    isDriversDataComplete,
    hasAssignedVehicle,
    pendingPayment,
    hasAwaitingPaymentTicket,
    pendingChecksIn,
    pendingChecksOut,
    isSignedOrPendingSignature,
  } = useSubscriptionContractFlags();

  const areChecksPending = pendingChecksOut || pendingChecksIn;

  const handleClick = () =>
    mutateAsync({ operatorFullName: operator?.name ?? '' })
      .then(() => {
        addSuccessNotification(
          intl.formatMessage(messages.contractSentSuccessfully)
        );
      })
      .catch(({ response }: AxiosError) => {
        switch (response?.status) {
          case 409:
            addErrorNotification(
              formatMessage(messages.vehicleAssignedToAnotherActiveContract)
            );
            break;
          default:
            addErrorNotification();
        }
      });

  if (status !== 'BOOKED' && status !== 'DRAFT') {
    return null;
  }

  return (
    <Button
      emphasis="high"
      onClick={handleClick}
      loading={isPending}
      className="w-[48px] p-0 text-center -ml-2"
      disabled={
        !isDriversDataComplete ||
        !hasAssignedVehicle ||
        (requiresDeposit && !hasDepositBeenCharged) ||
        pendingPayment ||
        hasAwaitingPaymentTicket ||
        areChecksPending ||
        isSignedOrPendingSignature
      }
      aria-label={intl.formatMessage(messages.signByEmail)}
    >
      <MdSend />
    </Button>
  );
};
