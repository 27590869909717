import { MdClose } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  useRemoveContractManualAdjustmentMutation,
} from '@cooltra/station-based-api';
import { Icon } from '@cooltra/ui';

import { useNotification } from '~/hooks';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type ValueAdjustmentProps = {
  contractId: string;
  className?: string;
  payment: ContractPayment;
};

export const ValueAdjustment = ({
  contractId,
  className,
  payment: { manualAdjustments, status },
}: ValueAdjustmentProps) => {
  const { formatMessage } = useIntl();
  const { mutateAsync } = useRemoveContractManualAdjustmentMutation(contractId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const handleRemoveTicketItem = (ticketItemId: string) => () =>
    mutateAsync({ manualAdjustmentTicketItemId: ticketItemId })
      .then(() =>
        addSuccessNotification(
          formatMessage(messages.removeSuccessNotification)
        )
      )
      .catch(() => addErrorNotification());

  if (!manualAdjustments?.length) {
    return null;
  }

  return (
    <div className={className}>
      <h3 className="text-sm text-neutral-800">
        <FormattedMessage {...messages.valueAdjustment} />
      </h3>
      <ul>
        {manualAdjustments.map(
          ({ ticketItemId, description, value, isRecurrent }) => (
            <li
              key={ticketItemId}
              data-testid={ticketItemId}
              className="flex justify-between my-2 gap-8 text-neutral-800"
            >
              {isRecurrent ? (
                <div>
                  {description} ({formatMessage(messages.recurrent)})
                </div>
              ) : (
                <div>{description}</div>
              )}
              <div className="relative">
                <Amount {...value} />
                {status === 'PENDING' && (
                  <button
                    className="absolute -right-8"
                    onClick={handleRemoveTicketItem(ticketItemId)}
                  >
                    <Icon
                      aria-label={formatMessage(messages.remove)}
                      className="text-danger-500 font-semibold text-2xl"
                    >
                      <MdClose />
                    </Icon>
                  </button>
                )}
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};
