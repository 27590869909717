import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';

import { getPenaltyMessage } from '~/utils/penalties';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type PenaltiesProps = {
  className?: string;
  payment: ContractPayment;
};

export const Penalties = ({
  className,
  payment: { penalties },
}: PenaltiesProps) => {
  if (!penalties?.length) {
    return null;
  }

  return (
    <div className={className}>
      <h3 className="text-sm text-neutral-800">
        <FormattedMessage {...messages.penalties} />
      </h3>
      <div>
        {penalties.map(({ id, name, price }) => (
          <div
            key={id}
            className="flex justify-between my-2 gap-8 text-neutral-800"
          >
            <FormattedMessage tagName="span" {...getPenaltyMessage(name)} />
            <Amount {...price} />
          </div>
        ))}
      </div>
    </div>
  );
};
