import { useContractQuery } from '@cooltra/station-based-api';
import { CopyButton } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import ReactQrCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

import { getEnvVariable } from '~/utils/environment';

import messages from './messages';

export type QRPaymentProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  isDeposit?: boolean;
};

export const QRPayment = ({
  id,
  isDeposit = false,
  className,
  ...rest
}: QRPaymentProps) => {
  const { contractId = '' } = useParams();

  const contractQuery = useContractQuery(contractId, {
    enabled: false,
  });

  const language = contractQuery.data?.user?.language.toLowerCase() || 'en';
  const domain = getEnvVariable('COOLTRA_WEB_DOMAIN');

  const publicQRLink = isDeposit
    ? `${domain}/${language}/contracts/${contractId}/pending-deposits/${id}`
    : `${domain}/${language}/contracts/${contractId}/pay-ticket/${id}`;

  return (
    <div className={classNames('text-center', className)} {...rest}>
      <h2 className="text-xl">
        <FormattedMessage {...messages.stripeQRPaymentMethod} />
      </h2>
      <div className="pt-16" data-testid="STRIPE_QR">
        <ReactQrCode
          size={382}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={publicQRLink}
          viewBox="0 0 256 256"
        />
      </div>
      <div>
        <CopyButton
          type="button"
          className="text-primary-500 font-semibold py-9"
          value={publicQRLink}
          label={
            <span className="text-primary-500 font-semibold pl-1">
              <FormattedMessage {...messages.copyLink} />
            </span>
          }
        />
      </div>
    </div>
  );
};
