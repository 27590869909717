import {
  useLocationQuery,
  useVehicleCurrentActivityQuery,
  Vehicle,
} from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Card, Divider, Placeholder } from '@cooltra/ui';
import { classNames, stringifyParams } from '@cooltra/utils';

import { useVehicleQuery } from '~/hooks';

import { Location } from '../Location/Location';
import { TakenBy } from '../TakenBy/TakenBy';
import { WorkUnitBy } from '../WorkUnitBy/WorkUnitBy';
import { CurrentRentalOrReservation } from '../CurrentRentalOrReservation/CurrentRentalOrReservation';
import { Geolocation } from '../Geolocation/Geolocation';
import { CurrentActivityHeader } from '../CurrentActivityHeader/CurrentActivityHeader';

import messages from './messages';

export type CurrentActivityProps = Pick<Vehicle, 'vehicleId'>;

export const CurrentActivity = ({ vehicleId }: CurrentActivityProps) => {
  const { data: vehicle } = useVehicleQuery(vehicleId);

  const { data: location, ...locationQuery } = useLocationQuery(
    vehicle?.locationId || '',
    { enabled: !!vehicle?.locationId }
  );

  const { data: currentActivity, ...curentActivityQuery } =
    useVehicleCurrentActivityQuery(vehicle?.vehicleId || '', {
      enabled: !!vehicle?.vehicleId,
    });

  const linkToVehicles = `/vehicles?${stringifyParams({
    location: vehicle?.locationId,
  })}`;

  const getCurrentActivity = () => {
    if (
      (vehicle?.locationId && locationQuery.isLoading) ||
      curentActivityQuery.isLoading
    ) {
      return (
        <div className="pt-1 pb-5">
          <Placeholder className="w-16 h-2 mb-3" />
          <Placeholder className="w-32 h-2" />
        </div>
      );
    }

    if (currentActivity?.rental || currentActivity?.reservation) {
      return (
        <CurrentRentalOrReservation
          rental={currentActivity.rental}
          reservation={currentActivity.reservation}
        />
      );
    }

    if (vehicle?.takenBy) {
      return <TakenBy takenBy={vehicle.takenBy} takenAt={vehicle.takenAt} />;
    }

    if (location) {
      return (
        <Location
          vehicleId={vehicle?.vehicleId}
          location={location}
          href={linkToVehicles}
          inLocationFrom={vehicle?.inLocationFrom}
        />
      );
    }

    return (
      <span className="text-neutral-300 text-sm">
        <FormattedMessage {...messages.noCurrentActivity} />
      </span>
    );
  };

  return (
    <div>
      <CurrentActivityHeader vehicle={vehicle} />
      <Card
        className={classNames(
          (!!currentActivity?.rental || !!currentActivity?.reservation) &&
            'bg-success-50/50'
        )}
      >
        {vehicle?.workUnitBy && <WorkUnitBy workUnitBy={vehicle.workUnitBy} />}
        <div className="pt-6 pb-5 px-8">{getCurrentActivity()}</div>
        <div className="px-8">
          <Divider />
          <div className="py-4">
            <Geolocation vehicleId={vehicleId} />
          </div>
        </div>
      </Card>
    </div>
  );
};
