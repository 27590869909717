import { FormattedMessage } from 'react-intl';
import { FormRadioGroupCustomField } from '@cooltra/form';
import { DepositMethod } from '@cooltra/station-based-api';

import { DepositMethodOption } from '~/common/Contract/DepositMethodOption/DepositMethodOption';

import messages from './messages';

type FormRadioGroupDepositsFieldProps = {
  hasLinkedPaymentMethod: boolean;
  amount: string;
};

export const FormRadioGroupDepositsField = ({
  hasLinkedPaymentMethod,
  amount,
}: FormRadioGroupDepositsFieldProps) => {
  const isThereAnyDepositMethod = hasLinkedPaymentMethod && Number(amount) > 0;
  const depositMethods: DepositMethod[] = isThereAnyDepositMethod
    ? ['BOOKING_CARD', 'TPV']
    : ['TPV', 'STRIPE_QR'];

  return (
    <FormRadioGroupCustomField
      id="deposit-method"
      name="method"
      legend={
        <label
          htmlFor="deposit-method"
          className="block text-xl font-semibold text-neutral-700 mb-8 text-center"
        >
          <FormattedMessage {...messages.title} />
        </label>
      }
      items={depositMethods.map((method) => ({
        label: '',
        value: method,
      }))}
      renderRadio={({ checked, value }) => (
        <DepositMethodOption type={value as DepositMethod} checked={checked} />
      )}
    />
  );
};
