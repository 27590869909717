import { FormSelectField, useFormContext } from '@cooltra/form';
import { useSubscriptionsQuery } from '@cooltra/api';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFormValues } from '../../Form';

import messages from './messages';

export const Subscription = () => {
  const {
    values: { editMode },
  } = useFormContext<CooltraForEmployeesFormValues>();
  const { data: subscriptions, isLoading } = useSubscriptionsQuery();

  const subscriptionsOptions = subscriptions?.map((subscription) => ({
    label: subscription.title,
    value: subscription.subscriptionId,
  }));

  return (
    <FormSelectField
      name="subscription"
      id="subscription"
      isLoading={isLoading}
      options={subscriptionsOptions}
      disabled={editMode}
      label={
        <InputLabel htmlFor="subscription">
          <FormattedMessage {...messages.subscription} />
        </InputLabel>
      }
    />
  );
};
