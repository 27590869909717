import { DepositCard } from './DepositCard';
import { DepositCardHeading } from './DepositCardHeading/DepositCardHeading';

export const NoDeposit = () => (
  <DepositCard>
    <div className="flex justify-between items-center">
      <DepositCardHeading />
      <span>-</span>
    </div>
  </DepositCard>
);
