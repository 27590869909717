import {
  useContractPaymentsQuery,
  useContractQuery,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { useParams } from 'react-router-dom';

import { isTestingEnv } from '~/utils/e2e';

import {
  isDriversDataComplete,
  isTaxIdRequired,
} from './is-drivers-data-complete';
import { areChecksInCompleted, areChecksOutCompleted } from './checks';

export const useSubscriptionContractFlags = () => {
  const { contractId = '' } = useParams();

  const { data: servicePoints } = useServicePointsQuery(
    {},
    {
      enabled: isTestingEnv(),
    }
  );

  const { data: contract } = useContractQuery(contractId, {
    enabled: isTestingEnv(),
  });

  const { data: payments } = useContractPaymentsQuery(contractId, {
    enabled: isTestingEnv(),
  });

  if (!contract || !payments || !servicePoints) {
    return {
      pendingChecksIn: false,
      pendingChecksOut: false,
      areDetailsEditable: false,
      isTaxIdRequired: false,
      hasDriversDataWarning: false,
      hasAssignedVehicle: false,
      requiresDeposit: false,
      hasDepositBeenCharged: false,
      hasDepositPendingCharge: false,
      hasPaidTicket: false,
      isVehicleRemovable: false,
      hasAwaitingPaymentTicket: false,
      isDriversDataComplete: false,
      pendingPayment: false,
      isSignedOrPendingSignature: false,
      hasPendingTicket: false,
      isPendingSignature: false,
      isSignedAndAwaitingActivation: false,
      isActive: false,
    };
  }

  const { status, vehicle } = contract;
  const areDetailsEditable =
    status === 'DRAFT' || status === 'NEW' || status === 'BOOKED';

  const servicePointCountry = servicePoints?.find(
    ({ id }) => id === contract.details?.servicePointId
  )?.country;

  const hasDriversDataWarning =
    !isDriversDataComplete(contract, servicePointCountry) &&
    !['ACTIVE', 'CLOSED'].includes(status);

  const hasAssignedVehicle = !!contract.vehicle?.assignedVehicle;

  const isBike = !!vehicle && vehicle.category === 'EBIKE';

  const pendingChecksOut =
    status === 'ACTIVE' && !areChecksOutCompleted(contract, isBike);

  const pendingChecksIn = !areChecksInCompleted(contract, isBike);

  const requiresDeposit =
    !!contract.coverage?.deposit.value &&
    (status === 'BOOKED' || status === 'DRAFT');

  const hasDepositBeenCharged =
    !!contract.deposit &&
    !!contract.deposit.withheldAt &&
    !contract.deposit.releasedAt;

  const hasDepositPendingCharge =
    !!contract.deposit && !contract.deposit.withheldAt;

  const pendingPayment = contract.pendingAmount.value > 0;

  const hasPaidTicket = payments.some(({ status }) => status === 'PAID');

  const isVehicleRemovable =
    (status === 'DRAFT' || status === 'BOOKED') &&
    !hasPaidTicket &&
    !hasDepositBeenCharged;

  const hasAwaitingPaymentTicket = payments.some(
    ({ status }) => status === 'AWAITING_PAYMENT'
  );

  const isPendingSignature =
    contract.documents?.some((contract) => contract.status === 'SENT') &&
    contract.status === 'BOOKED';

  const isSignedAndAwaitingActivation =
    contract.documents?.some((contract) => contract.status === 'SIGNED') &&
    contract.status === 'BOOKED';

  const isSignedOrPendingSignature =
    isPendingSignature || isSignedAndAwaitingActivation;

  const hasPendingTicket = payments.some(({ status }) => status === 'PENDING');

  const isActive = status === 'ACTIVE';

  return {
    pendingChecksIn,
    pendingChecksOut,
    areDetailsEditable,
    isTaxIdRequired: isTaxIdRequired({
      servicePointCountry,
      taxIdCountry: contract.user?.taxIdCountry,
    }),
    hasDriversDataWarning,
    hasAssignedVehicle,
    requiresDeposit,
    hasDepositBeenCharged,
    hasDepositPendingCharge,
    hasPaidTicket,
    isVehicleRemovable,
    hasAwaitingPaymentTicket,
    isDriversDataComplete: isDriversDataComplete(contract, servicePointCountry),
    pendingPayment,
    isSignedOrPendingSignature,
    hasPendingTicket,
    isPendingSignature,
    isSignedAndAwaitingActivation,
    isActive,
  };
};
