import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  coverage: {
    defaultMessage: 'Coverage',
  },
  accessories: {
    defaultMessage: 'Accessories',
  },
  total: {
    defaultMessage: 'Total',
  },
  vehicleType: {
    defaultMessage: '{vehicleTypeName} or similar ({contractDays} days)',
  },
  days: {
    defaultMessage:
      '{name} ({contractDays, plural, one {# day} other {# days}})',
  },
  totalOriginalPrice: {
    defaultMessage: 'Original price',
  },
  couponDiscount: {
    defaultMessage: 'Coupon discount',
  },
  penalties: {
    defaultMessage: 'Penalties',
  },
  deposit: {
    defaultMessage: 'Deposit',
  },
  valueAdjustment: {
    defaultMessage: 'Price adjustments',
  },
  remove: {
    defaultMessage: 'Remove',
  },
  removeSuccessNotification: {
    defaultMessage: 'Price adjustment removed successfully',
  },
  recurrent: {
    defaultMessage: 'Recurrent',
  },
});
