import { Rental } from '@cooltra/api';

import { MapLine } from '../../Map';

export type RouteLineProps = Pick<Rental, 'dropOff' | 'pickUp'>;

export const RouteLine = ({ dropOff, pickUp }: RouteLineProps) => {
  const coordinates = [
    [pickUp.longitude, pickUp.latitude],
    dropOff && [dropOff.longitude, dropOff.latitude],
  ].filter(Boolean) as number[][];

  return (
    <MapLine
      sourceId="rental-source"
      layerId="vehicle-route"
      paint={{
        'line-color': dropOff ? '#8A8A8A' : '#31C48D',
        'line-width': 6,
        'line-dasharray': [0.5, 2],
      }}
      coordinates={coordinates}
    />
  );
};
