import { defineMessages } from 'react-intl';

export default defineMessages({
  refundSuccess: {
    defaultMessage: 'Refund done successfully',
  },
  refundValidationError: {
    defaultMessage:
      'You must remove all pending tickets before refunding paid tickets',
  },
  areYourSureToRefundTicket: {
    defaultMessage: 'Are you sure you want to proceed with the refund?',
  },
  tpvOperationId: {
    defaultMessage: 'TPV Operation ID: {operationId}',
  },
  cardLastFourDigits: {
    defaultMessage: 'Card: **** {lastFourDigits}',
  },
  refund: {
    defaultMessage: 'Refund',
  },
  confirmRefund: {
    defaultMessage: 'Refund {amount}',
  },
  refunded: {
    defaultMessage: 'Refunded',
  },
});
