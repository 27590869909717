import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useGeofencesQuery, Rental } from '@cooltra/api';
import { LngLatBounds } from 'mapbox-gl';
import { MapRef, NavigationControl } from 'react-map-gl';
import { isBike } from '@cooltra/vehicle-utils';

import { isTestingEnv } from '~/utils/e2e';

import { BaseMap, BonusZoneById, Geofences } from '../../Map';

import { PickUpMarker, DropOffMarker, CoincidentMarker } from './RentalMarkers';
import { RouteLine } from './RouteLine';

export type RentalMapProps = {
  rental: Rental;
  mapBounds?: [number, number][];
  children?: ReactNode;
  id: string;
};

export const RentalMap = ({
  rental,
  children,
  mapBounds = [],
  id,
}: RentalMapProps) => {
  const mapRef = useRef<MapRef | null>(null);
  const isBikeRental = isBike({ telematics: rental.vehicle.telematics });
  const vehicleType = isBikeRental ? 'BIKE' : 'SCOOTER';
  const { data: geofences } = useGeofencesQuery({
    vehicleType,
  });
  const fitMapBounds = useCallback(() => {
    const bounds: LngLatBounds = new LngLatBounds(
      [rental.pickUp.longitude, rental.pickUp.latitude],
      [rental.pickUp.longitude, rental.pickUp.latitude]
    );

    if (rental.dropOff) {
      bounds.extend(
        new LngLatBounds(
          [rental.dropOff.longitude, rental.dropOff.latitude],
          [rental.dropOff.longitude, rental.dropOff.latitude]
        )
      );
    }

    [...mapBounds].forEach((coordinates) => {
      bounds.extend(coordinates);
    });

    mapRef.current?.fitBounds(bounds, {
      padding: 20,
      duration: 1000,
    });
  }, [
    mapBounds,
    rental.dropOff,
    rental.pickUp.latitude,
    rental.pickUp.longitude,
  ]);

  useEffect(() => {
    fitMapBounds();
  }, [fitMapBounds]);

  if (isTestingEnv()) {
    return <div className="h-full w-full bg-neutral-100" />;
  }

  const getRentalMarkers = () => {
    const { pickUp, dropOff } = rental;

    if (
      pickUp.latitude === dropOff?.latitude &&
      pickUp.longitude === dropOff.longitude
    ) {
      return (
        <CoincidentMarker
          latitude={pickUp.latitude}
          longitude={pickUp.longitude}
        />
      );
    }

    return (
      <>
        <PickUpMarker latitude={pickUp.latitude} longitude={pickUp.longitude} />
        {dropOff && (
          <DropOffMarker
            latitude={dropOff.latitude}
            longitude={dropOff.longitude}
          />
        )}
      </>
    );
  };

  return (
    <BaseMap
      id={id}
      ref={mapRef}
      initialViewState={{
        zoom: 13,
        latitude: rental.pickUp.latitude,
        longitude: rental.pickUp.longitude,
      }}
      style={{ width: '100%', height: '100%' }}
      onLoad={fitMapBounds}
    >
      <NavigationControl showZoom showCompass={false} />
      <Geofences geofences={geofences} vehicleType={vehicleType} />
      {rental.bonusZoneId && (
        <BonusZoneById
          bonusZoneId={rental.bonusZoneId}
          system={rental.system}
        />
      )}
      {getRentalMarkers()}
      <RouteLine dropOff={rental.dropOff} pickUp={rental.pickUp} />
      {children}
    </BaseMap>
  );
};
