import { Alert } from '@cooltra/ui';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { RentalTimelineItemReduced } from '~/libs/reduce-rental-timeline';

import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';
import { RentalTimelineTick } from '../RentalTimelineTick';

import messages from './messages';

export type CheckoutDismissibleErrorsItemProps = {
  dismissibleErrors: string[];
  isLast: boolean;
} & Pick<RentalTimelineItemReduced, 'occurredOn'>;

export const CheckoutDismissibleErrorsItem = ({
  occurredOn,
  dismissibleErrors,
  isLast,
}: CheckoutDismissibleErrorsItemProps) => {
  if (dismissibleErrors.length === 0) {
    return <></>;
  }

  return (
    <div data-testid="CHECKOUT_WITH_DISMISSIBLE_ERRORS_ITEM" className="mb-8">
      <RentalTimelineRowLayout
        isLast={isLast}
        timeline={<RentalTimelineTick />}
        content={
          <div className="flex items-center text-xs">
            <span className="mr-2">
              <FormattedTime
                value={occurredOn}
                hour="2-digit"
                hourCycle="h23"
                minute="2-digit"
              />
            </span>
            <span className="font-semibold text-success-500">
              <FormattedMessage {...messages.succeeded} />
            </span>
          </div>
        }
      />
      <RentalTimelineRowLayout
        timeline={isLast && <div className="bg-neutral-0 inset-0 absolute" />}
        content={
          <Alert variant="warning" className="ml-9 mt-1">
            <p className="text-sm mb-1">
              <FormattedMessage
                {...messages.checkOutAttempt}
                values={{
                  error: (
                    <span className="font-semibold">
                      <FormattedMessage {...messages.dismissedErrors} />:
                    </span>
                  ),
                }}
              />
            </p>
            <ul>
              {dismissibleErrors.map((error) => (
                <li
                  className="text-sm text-neutral-1000 list-disc ml-4"
                  key={error}
                >
                  {error}
                </li>
              ))}
            </ul>
          </Alert>
        }
      />
    </div>
  );
};
