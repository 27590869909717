import { Accessories } from './Accessories';
import { Coverage } from './Coverage';
import { Deposit } from './Deposit';
import { Penalties } from './Penalties';
import { Total } from './Total';
import { Vehicle } from './Vehicle';
import { ValueAdjustment } from './ValueAdjustment';

export const ContractPaymentItems = {
  Accessories,
  Coverage,
  Deposit,
  Penalties,
  Total,
  Vehicle,
  ValueAdjustment,
};
