import {
  useVehicleQuery as useApiVehicleQuery,
  Vehicle,
  VehicleListItem,
} from '@cooltra/api';
import { AxiosError } from 'axios';
import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';

export const useVehicleQuery = (
  vehicleId: string,
  options: Omit<
    UseQueryOptions<Vehicle, AxiosError, Vehicle, string[]>,
    'queryKey'
  > = {}
) => {
  const queryClient = useQueryClient();

  return useApiVehicleQuery(vehicleId, {
    initialData: () => {
      const vehicles = queryClient.getQueryData<VehicleListItem[]>([
        'vehicles',
      ]);

      if (!vehicles) {
        return undefined;
      }

      const vehicleListItem = vehicles.find(
        (vehicle) => vehicle.vehicleId === vehicleId
      );

      if (!vehicleListItem) {
        return undefined;
      }

      return {
        ...vehicleListItem,
        takenAt: '',
        createdAt: '',
      };
    },
    ...options,
  });
};
