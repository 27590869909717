import { Badge, Card, Divider } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Contract, SubscriptionPlan } from '@cooltra/station-based-api';
import { differenceInCalendarMonths } from 'date-fns';

import { getContractTermMessage } from '~/utils/contract';
import {
  Amount,
  ContractOverviewDates,
  ContractOverviewExtras,
  ContractOverviewHistory,
  ContractOverviewUser,
  ContractOverviewVehicle,
  ContractTypeLabel,
  Log,
  StripeCustomerLink,
  SubscriptionContractState,
} from '~/common';

import messages, { planLabels } from './messages';

export type SubscriptionContractOverviewProps = {
  contract: Contract;
};

export const getContractPlanMessage = (plan: SubscriptionPlan) =>
  planLabels[plan];

export const SubscriptionContractOverview = ({
  contract,
}: SubscriptionContractOverviewProps) => {
  const { formatDate } = useIntl();

  const {
    monthsTerm,
    details,
    type,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    user,
    plan,
    monthlyCharge,
    vehicle,
    status,
    insurance,
    accessories,
    nextRenewalDate,
    linkedPaymentMethod,
  } = contract;
  const { formatMessage } = useIntl();

  return (
    <div
      className="mx-auto max-w-4xl pt-4 pb-10"
      data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW"
    >
      <div className="grid grid-cols-2 gap-6 mb-6">
        <Card
          className="p-8"
          data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_TERMS"
        >
          <span className="block text-xs text-neutral-500 font-medium mb-2">
            <FormattedMessage {...messages.subscription} />
          </span>
          <span className="block text-2xl font-semibold text-neutral-800 mb-2">
            <FormattedMessage {...getContractTermMessage(monthsTerm)} />
          </span>
          <div className="flex gap-2">
            <Badge key={plan} emphasis="low">
              {formatMessage(getContractPlanMessage(plan!))}
            </Badge>
            <SubscriptionContractState />
          </div>
          <div>
            <div className="flex justify-between gap-8 mt-6">
              <div>
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.totalDuration} />
                </span>
                <span className="text-base text-neutral-800">
                  <FormattedMessage
                    {...messages.months}
                    values={{
                      total: Math.ceil(
                        differenceInCalendarMonths(
                          new Date(),
                          new Date(
                            `${details!.startDate}T${details!.startTime}`
                          )
                        ) + 1
                      ),
                    }}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.nextReview} />
                </span>
                {nextRenewalDate && (
                  <span className="text-base text-neutral-800 self-end">
                    {formatDate(nextRenewalDate, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Card>

        <Card
          className="p-8"
          data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_CHARGES"
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <span className="block text-xs text-neutral-500 font-medium mb-2">
                <FormattedMessage {...messages.monthlyCharge} />
              </span>
              <span className="text-neutral-800 text-2xl font-semibold">
                <span className="flex items-center gap-1">
                  {monthlyCharge ? (
                    <Amount
                      value={monthlyCharge!.value}
                      currency={monthlyCharge!.currency}
                    />
                  ) : (
                    <span className="text-neutral-800 text-xl font-semibold">
                      -
                    </span>
                  )}
                </span>
              </span>
            </div>
            <div className="flex justify-between gap-8 mt-6">
              <div className="flex flex-col">
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.savedCreditCard} />
                </span>
                {linkedPaymentMethod && (
                  <span className="text-base text-neutral-800">
                    <StripeCustomerLink paymentMethod={linkedPaymentMethod} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Card
        className="flex"
        data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_DETAILS"
      >
        {type && <ContractTypeLabel type={type} />}
        <div className="w-48 lg:w-60 shrink-0 px-8 lg:px-10 py-8 bg-neutral-100/20 flex flex-col gap-64">
          {user && <ContractOverviewUser user={user} />}
        </div>
        <div className="w-full p-8">
          {details && <ContractOverviewDates details={details} />}
          {vehicle && <Divider />}
          <ContractOverviewVehicle vehicle={vehicle} status={status} />
          {(accessories || insurance) && <Divider />}
          <ContractOverviewExtras
            insurance={insurance}
            accessories={accessories}
          />
        </div>
      </Card>
      <div className="flex pt-5 gap-10">
        {createdAt && (
          <div className="max-w-56 truncate">
            <Log
              action="create"
              user="operator"
              userId={createdBy}
              date={createdAt}
            />
          </div>
        )}
        {updatedAt && updatedBy && (
          <>
            <div className="py-1">
              <Divider direction="vertical" className="h-full" />
            </div>
            <div className="max-w-56 truncate">
              <Log
                action="update"
                user="operator"
                userId={updatedBy}
                date={updatedAt}
              />
            </div>
          </>
        )}
      </div>
      <div className="py-12">
        <ContractOverviewHistory contract={contract} />
      </div>
    </div>
  );
};
