import { FormattedMessage } from 'react-intl';
import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { RouterTab } from '@cooltra/navigation';

import { PaidTicket, TicketPendingPayment } from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type SubscriptionPastChargesProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const SubscriptionPastCharges = ({
  contract,
  payments,
}: SubscriptionPastChargesProps) => {
  const { isSignedOrPendingSignature } = useSubscriptionContractFlags();
  const paidPayments = payments.filter(
    ({ status }) => status === 'PAID' || status === 'REFUNDED'
  );
  const awaitingPayments =
    payments.filter(({ status }) => status === 'AWAITING_PAYMENT') || [];

  if (!paidPayments.length && !awaitingPayments.length) {
    return null;
  }

  return (
    <>
      <div className="my-8">
        <RouterTab to=".">
          <FormattedMessage {...messages.pastCharges} />
        </RouterTab>
      </div>
      <div
        data-testid="SUBSCRIPTION_PAST_CHARGES"
        className="flex flex-col gap-4"
      >
        {awaitingPayments.map((awaitingPaymentTicket) => (
          <TicketPendingPayment
            contractId={contract.contractId}
            ticket={awaitingPaymentTicket}
            key={awaitingPaymentTicket.id}
          />
        ))}
        {paidPayments.map((paidPayment) => (
          <PaidTicket
            refundDisabled={isSignedOrPendingSignature}
            key={paidPayment.id}
            contractId={contract.contractId}
            contractStatus={contract.status}
            payment={paidPayment}
          />
        ))}
      </div>
    </>
  );
};
