import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Input, InputProps } from '@cooltra/ui';
import { ReactNode } from 'react';

export type AmountInputFieldProps = NumericFormatProps<InputProps> & {
  error?: ReactNode;
  label?: ReactNode;
};

export const AmountInputField = ({
  error,
  label,
  allowNegative = false,
  className,
  ...rest
}: AmountInputFieldProps) => (
  <div className={className}>
    {label && <div className="flex flex-row items-end mb-1">{label}</div>}
    <NumericFormat
      customInput={Input}
      allowNegative={allowNegative}
      decimalSeparator="."
      decimalScale={2}
      invalid={!!error}
      {...rest}
    />
    {error && <div className="flex flex-row items-end mt-1">{error}</div>}
  </div>
);
