import { FormattedMessage, FormattedTime } from 'react-intl';
import { Card } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { WorkShiftWorkUnit } from '@cooltra/api/src/modules/work-shifts/types';

import { calculateTimeDifference } from '~/libs/time-difference';
import { WorkUnitInfo } from '~/common';

import { WorkActionRowLayout } from '../WorkActionRowLayout/WorkActionRowLayout';
import { WorkUnitDot } from '../WorkUnitDot/WorkUnitDot';

import messages from './messages';

type WorkUnitRowProps = {
  workUnitPosition: number;
  workUnit: WorkShiftWorkUnit;
};

export const WorkUnitRow = ({
  workUnitPosition,
  workUnit,
}: WorkUnitRowProps) => {
  const getDifferenceBetweenCreationAndFinishing = () => {
    let finishedAt = new Date();

    if (workUnit.finishedAt) {
      finishedAt = new Date(workUnit.finishedAt);
    }

    const timeDifference = calculateTimeDifference(
      new Date(workUnit.createdAt),
      finishedAt
    );

    return (
      <div className="h-full flex items-center">
        <span className="absolute text-xs text-neutral-600 bg-neutral-50 -translate-x-1/2 whitespace-nowrap">
          {timeDifference}
        </span>
      </div>
    );
  };

  return (
    <div className="relative">
      <WorkActionRowLayout
        className="absolute w-full h-full"
        timeline={getDifferenceBetweenCreationAndFinishing()}
      />
      <WorkActionRowLayout
        time={
          <span
            className={classNames(
              'text-sm tabular-nums font-semibold',
              workUnit.skipped ? 'text-neutral-400' : 'text-neutral-800'
            )}
          >
            <FormattedTime
              value={workUnit.createdAt}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        }
        timeline={
          <WorkUnitDot
            value={workUnitPosition + 1}
            skipped={workUnit.skipped}
          />
        }
        content={
          <div className="flex flex-col gap-2">
            <span
              className={classNames(
                'block font-semibold text-sm mt-0.5',
                workUnit.skipped && 'text-neutral-400'
              )}
            >
              {workUnit.skipped ? (
                <FormattedMessage {...messages.workSkipped} />
              ) : (
                <FormattedMessage {...messages.workStarted} />
              )}
            </span>
            <Card className="w-full overflow-hidden shadow-md">
              <WorkUnitInfo {...workUnit} />
            </Card>
          </div>
        }
      />
      <WorkActionRowLayout
        className="pt-4"
        timeline={
          <div className="relative w-2 h-2 rounded-full bg-neutral-300" />
        }
        content={
          workUnit.finishedAt ? (
            <span className="block text-xs top-1 relative text-neutral-500">
              <FormattedMessage {...messages.workFinished} />
            </span>
          ) : (
            <span className="block text-xs top-1 relative text-success-500">
              <FormattedMessage {...messages.workInProgress} />
            </span>
          )
        }
      />
    </div>
  );
};
