import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Modal } from '@cooltra/ui';
import {
  Contract,
  Signature,
  useContractQuery,
  useSignContractMutation,
} from '@cooltra/station-based-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';

import { useNotification } from '~/hooks/useNotification';
import { useSubscriptionContractFlags, usePolling } from '~/hooks';

import messages from './messages';

import './sign-subscription-button.css';

export type SignSubscriptionButtonProps = {
  contract: Contract;
  buttonText?: ReactNode;
};

export const SignSubscriptionButton = ({
  contract,
  buttonText,
}: SignSubscriptionButtonProps) => {
  const { contractId, status, hasClickAndRide } = contract;

  const intl = useIntl();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { user: operator } = useAuth0();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const [url, setUrl] = useState<string | null>(null);
  const [isPollingTriggered, triggerPolling] = useState(false);
  const { mutateAsync, isPending } = useSignContractMutation(contractId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { refetch } = useContractQuery(contractId);
  const {
    hasDepositBeenCharged,
    requiresDeposit,
    isDriversDataComplete,
    hasAssignedVehicle,
    pendingPayment,
    hasAwaitingPaymentTicket,
    pendingChecksIn,
    pendingChecksOut,
    isSignedOrPendingSignature,
  } = useSubscriptionContractFlags();

  const isChecksPending = pendingChecksOut || pendingChecksIn;

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.event === 'completed') {
        toggleOff();
        triggerPolling(true);
      }
    },
    [toggleOff]
  );

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      if (
        contract.documents?.some((contract) => contract.status === 'SIGNED')
      ) {
        addSuccessNotification(
          intl.formatMessage(messages.successfulContractSigned)
        );
        navigate(`/contracts/subscription/${contractId}/overview`);
        return;
      }

      addErrorNotification();
    },
    interval: isPollingTriggered && status !== 'ACTIVE' ? 2500 : 0,
    maxAttempts: 10,
  });

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const handleClick = () =>
    mutateAsync({ operatorFullName: operator?.name ?? '' })
      .then((data: Signature) => {
        setUrl(data.url);
        toggleOn();
      })
      .catch(({ response }: AxiosError) => {
        switch (response?.status) {
          case 409:
            addErrorNotification(
              formatMessage(messages.vehicleAssignedToAnotherActiveContract)
            );
            break;
          default:
            addErrorNotification();
        }
      });

  if (status !== 'BOOKED' && status !== 'DRAFT') {
    return null;
  }

  return (
    <>
      <Modal
        className="h-full w-full"
        isOpen={isOpen}
        onRequestClose={toggleOff}
      >
        {url && (
          <iframe
            data-testid="SIGN_CONTRACT_MODAL"
            className="signature-modal-content w-full"
            src={url}
          ></iframe>
        )}
      </Modal>
      <Button
        emphasis="high"
        onClick={handleClick}
        loading={isPolling || isPending}
        disabled={
          isSignedOrPendingSignature ||
          !isDriversDataComplete ||
          !hasAssignedVehicle ||
          (requiresDeposit && !hasDepositBeenCharged) ||
          pendingPayment ||
          hasAwaitingPaymentTicket ||
          isChecksPending ||
          hasClickAndRide
        }
      >
        {buttonText || <FormattedMessage {...messages.sign} />}
      </Button>
    </>
  );
};
