import { Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { TopLoadingBar } from '@cooltra/ui';
import { ErrorBoundary, setUser } from '@sentry/react';
import { useEffect } from 'react';

import { Four0Four } from '~/common';
import { getEnvVariable } from '~/utils/environment';
import { PreferredSystemProvider } from '~/libs/preferred-system';
import { PollVehicleProvider } from '~/libs/polling-data';
import { PreferredServicePointsProvider } from '~/libs/preferred-service-points';

import { ContinuousReview } from '../User/ContinuousReview/ContinuousReview';
import { CookiesPolicy } from '../CookiesPolicy/CookiesPolicy';
import { Contract } from '../Contract';
import { Fines } from '../Fines';
import { Home } from '../Home/Home';
import { Incidents } from '../Incidents/Incidents';
import { Invoice } from '../Invoice';
import { MaasUser } from '../MaasUser/MaasUser';
import { Offers } from '../Offers/Offers';
import { OperatorAccount } from '../OperatorAccount/OperatorAccount';
import { POS } from '../POS/POS';
import { PriceLists } from '../PriceLists/PriceLists';
import { Pricing } from '../Pricing/Pricing';
import { ProblemReports } from '../ProblemReports/ProblemReports';
import { Rentals } from '../Rentals/Rentals/Rentals';
import { Services } from '../Maintenance/Services/Services';
import { Shifts } from '../Shifts/Shifts';
import { Tasks } from '../Tasks/Tasks';
import { TechnicalInspections } from '../Maintenance/TechnicalInspections/TechnicalInspections';
import { Users } from '../Users/Users';
import { Vehicles } from '../Vehicles/Vehicles';
import { VehiclesLocations } from '../VehiclesLocations';
import { ZonesRoutes } from '../Zones';
import { SubscriptionContract } from '../SubscriptionContract/SubscriptionContract';

import { PrefetchQueries } from './PrefetchQueries';
import { CrashPage } from './CrashPage/CrashPage';
import { NotAuthorized } from './NotAuthorized/NotAuthorized';
import { Settings } from './Settings/Settings';
import { Navigation } from './Navigation/Navigation';
import { CookiesModal } from './Cookies/CookiesModal';

const Main = () => {
  const { user } = useAuth0();

  useEffect(() => {
    if (user?.sub) {
      setUser({ id: user.sub });
    }
  }, [user?.sub]);

  const { data: claims, hasPermission } = useAuthClaimsQuery(
    getEnvVariable('NODE_ENV') === 'development'
      ? {}
      : {
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        }
  );
  const { language, messages } = usePreferredLanguage();

  if (!language || !claims) {
    return <TopLoadingBar />;
  }

  return (
    <IntlProvider locale={language} messages={messages}>
      <PreferredSystemProvider localStorageKey="zeus-web-preferred-system">
        <PreferredServicePointsProvider>
          <PollVehicleProvider>
            <PrefetchQueries />
            {hasPermission('view:zeus') ? (
              <div className="flex flex-col min-h-full bg-neutral-50">
                <Navigation />
                <ErrorBoundary fallback={<CrashPage />}>
                  <Routes>
                    <Route path="/account" element={<OperatorAccount />} />
                    <Route
                      path="/locations/*"
                      element={<VehiclesLocations />}
                    />
                    <Route path="/settings/*" element={<Settings />} />
                    <Route path="/users/*" element={<Users />} />
                    <Route path="/shifts/*" element={<Shifts />} />
                    <Route path="/maas/users/:id/*" element={<MaasUser />} />
                    <Route path="/vehicles/*" element={<Vehicles />} />
                    <Route path="/services/*" element={<Services />} />
                    <Route
                      path="/technical-inspections/*"
                      element={<TechnicalInspections />}
                    />
                    <Route path="/tasks/*" element={<Tasks />} />
                    <Route path="/incidents/*" element={<Incidents />} />
                    <Route path="/rentals/*" element={<Rentals />} />
                    <Route path="/offers/*" element={<Offers />} />
                    <Route path="/pricing/*" element={<Pricing />} />
                    <Route path="/invoices/:invoiceId" element={<Invoice />} />
                    <Route
                      path="/problem-reports/*"
                      element={<ProblemReports />}
                    />
                    <Route path="/cookies-policy" element={<CookiesPolicy />} />
                    <Route path="/" element={<Home />} />
                    <Route
                      path="/users-reviews"
                      element={<ContinuousReview />}
                    />
                    <Route path="/fines/*" element={<Fines />} />
                    <Route path="/zones/:system/*" element={<ZonesRoutes />} />
                    <Route path="/station-based/*" element={<PriceLists />} />
                    <Route path="/pos/*" element={<POS />} />
                    <Route
                      path="/contracts/short-term/:contractId/*"
                      element={<Contract />}
                    />
                    <Route
                      path="/contracts/subscription/:contractId/*"
                      element={<SubscriptionContract />}
                    />
                    <Route path="*" element={<Four0Four />} />
                  </Routes>
                </ErrorBoundary>
                {getEnvVariable('NODE_ENV') === 'production' && (
                  <CookiesModal />
                )}
              </div>
            ) : (
              <NotAuthorized />
            )}
          </PollVehicleProvider>
        </PreferredServicePointsProvider>
      </PreferredSystemProvider>
    </IntlProvider>
  );
};

export const MainWithAuthentication = withAuthenticationRequired(Main, {
  onRedirecting: () => <TopLoadingBar />,
});
