import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import {
  useContractPaymentsQuery,
  usePayTicketContractWithQRMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';
import { useQRPayment } from '~/libs/qr-payment';

import { DepositFormValues } from '../../ContractPayments/ContractDeposit/deposit-form';
import { PayTicketFormValues } from '../PayTicketFormValues';

import messages from './messages';

type StripeQRPaymentButtonProps = {
  contractId: string;
  onCloseModal: () => void;
  disabled: boolean;
};

export const StripeQRPaymentButton = ({
  contractId,
  onCloseModal,
  disabled,
}: StripeQRPaymentButtonProps) => {
  const {
    values: { billingServicePointId },
  } = useFormContext<PayTicketFormValues>();
  const { openModal } = useQRPayment();
  const { addErrorNotification } = useNotification();

  const { data: tickets = [] } = useContractPaymentsQuery(contractId, {
    enabled: false,
  });
  const ticketPending = tickets.find((ticket) => ticket.status === 'PENDING');
  const ticketId = ticketPending?.id || '';

  const { mutateAsync, isPending } = usePayTicketContractWithQRMutation(
    contractId,
    ticketId
  );
  const { initialValues, resetForm } = useFormContext<DepositFormValues>();

  const handleStripeQRSubmit = async () =>
    mutateAsync({
      billingServicePointId,
    })
      .then(() => {
        openModal({ id: ticketId, isDeposit: false });
        resetForm(initialValues);
        onCloseModal();
      })
      .catch(() => {
        addErrorNotification();
      });

  return (
    <Button
      onClick={handleStripeQRSubmit}
      loading={isPending}
      disabled={disabled}
      emphasis="high"
      className="mt-8"
    >
      <FormattedMessage {...messages.seeStripeQR} />
    </Button>
  );
};
