import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouterTab } from '@cooltra/navigation';

import { PriceListTypeContext } from '../PriceListTypeContext';

import messages from './messages';

export const PriceListsNavigationTab = () => {
  const { type } = useContext(PriceListTypeContext);

  const vehicleTypesUrl = `/station-based/price-lists/${type.toLowerCase()}/vehicle-types`;
  const accessoriesAndCoverageUrl = `/station-based/price-lists/${type.toLowerCase()}/accessories`;

  return (
    <div className="flex flex-1 shrink-0 gap-10">
      <RouterTab to={vehicleTypesUrl}>
        <FormattedMessage {...messages.vehicleTypes} />
      </RouterTab>
      <RouterTab to={accessoriesAndCoverageUrl}>
        <FormattedMessage {...messages.accessoriesAndCoverage} />
      </RouterTab>
    </div>
  );
};
