import { FormattedMessage } from 'react-intl';
import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { MdError } from 'react-icons/md';

import messages from './messages';

export type DepositCardHeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  warning?: boolean;
};

export const DepositCardHeading = ({
  className,
  warning = false,
  ...rest
}: DepositCardHeadingProps) => (
  <h2
    className={classNames(
      'text-lg text-neutral-800 flex items-center gap-2',
      className
    )}
    {...rest}
  >
    <FormattedMessage {...messages.deposit} />
    {warning && (
      <MdError
        className="text-warning-500 text-xl"
        data-testid="PENDING_DEPOSIT_ACTION"
      />
    )}
  </h2>
);
