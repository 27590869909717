import { defineMessages } from 'react-intl';

export default defineMessages({
  chargeDeposit: {
    defaultMessage: 'Charge deposit',
  },
  legend: {
    defaultMessage: 'Payment method',
  },
  confirm: {
    defaultMessage: 'Confirm deposit of €{amount}',
  },
  seeQR: {
    defaultMessage: 'See Stripe QR code',
  },
});
