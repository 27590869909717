import { defineMessages } from 'react-intl';

export default defineMessages({
  pendingPayment: {
    defaultMessage: 'Pending charge (awaiting payment)',
  },
  title: {
    defaultMessage: 'Charges at store',
  },
  createdAt: {
    defaultMessage: 'Created on ',
  },
  showStripeQR: {
    defaultMessage: 'Show QR',
  },
  verify: {
    defaultMessage: 'Verify payment',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Cancel charge',
  },
  success: {
    defaultMessage: 'Pending charge has been removed successfully',
  },
  cancelPendingTicketTitle: {
    defaultMessage: 'Remove pending charge',
  },
  cancelPendingTicketContent: {
    defaultMessage: 'Are you sure you want to cancel this pending charge?',
  },
});
