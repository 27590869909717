import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdInfoOutline } from 'react-icons/md';
import { ContractStatus } from '@cooltra/station-based-api';

import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type SubscriptionContractEditionAlertProps = {
  contractStatus: ContractStatus;
};

export const SubscriptionContractEditionAlert = ({
  contractStatus,
}: SubscriptionContractEditionAlertProps) => {
  const { hasDepositBeenCharged, hasPaidTicket } =
    useSubscriptionContractFlags();

  if (
    (!hasPaidTicket && !hasDepositBeenCharged) ||
    contractStatus === 'ACTIVE'
  ) {
    return null;
  }

  return (
    <Card className="p-6 mb-10 shadow bg-neutral-100/50">
      <div className="h-full flex gap-4">
        <div className="grow">
          <h6 className="text-lg text-neutral-800 font-semibold flex items-center gap-3">
            <MdInfoOutline className="text-2xl" />
            <FormattedMessage {...messages.alertTitle} />
          </h6>
          <ul className="list-disc pl-14">
            {hasPaidTicket && (
              <li className="text-neutral-800">
                <FormattedMessage {...messages.shouldRefundPaidTickets} />
              </li>
            )}
            {hasDepositBeenCharged && (
              <li className="text-neutral-800">
                <FormattedMessage {...messages.shouldReturnDeposit} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </Card>
  );
};
