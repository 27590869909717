import { Button, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormInputField, FormButton } from '@cooltra/form';

import { FormDateInputField } from '../../FormattedInput';
import { AddCreditForm } from '../AddCreditForm';

import messages from './messages';

export type AddCreditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  title: string;
};

export const AddCreditModal = ({
  isOpen,
  onClose,
  userId,
  title,
}: AddCreditModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      className="max-w-lg min-w-md"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div data-testid="ADD_CREDIT_MODAL" className="pb-12 px-12">
        <h6 className="text-neutral-400 text-lg mb-6">
          <FormattedMessage {...messages.addCredit} />
        </h6>
        <AddCreditForm userId={userId} title={title} onSuccess={onClose}>
          <Form className="flex flex-col gap-6">
            <FormInputField
              name="title"
              id="title"
              placeholder={formatMessage(messages.creditTitle)}
              label={
                <InputLabel htmlFor="title">
                  <FormattedMessage {...messages.title} /> *
                </InputLabel>
              }
            />
            <FormInputField
              name="amount"
              id="amount"
              placeholder="7.50"
              label={
                <InputLabel htmlFor="amount">
                  <FormattedMessage {...messages.amount} /> (€) *
                </InputLabel>
              }
            />
            <FormDateInputField
              name="expiresAt"
              id="expiresAt"
              label={
                <InputLabel htmlFor="expiresAt" className="mb-1">
                  <FormattedMessage {...messages.validUntil} />
                </InputLabel>
              }
            />
            <div className="flex mt-6 gap-4">
              <Button type="button" className="w-full" onClick={onClose}>
                <FormattedMessage {...messages.cancel} />
              </Button>
              <FormButton disableInvalid className="w-full" emphasis="high">
                <FormattedMessage {...messages.save} />
              </FormButton>
            </div>
          </Form>
        </AddCreditForm>
      </div>
    </Modal>
  );
};
