import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { ByOperator, DateTime } from '~/common';

import messages from './messages';

export type TakenByProps = Pick<Vehicle, 'takenBy' | 'takenAt'>;

export const TakenBy = ({ takenBy, takenAt }: TakenByProps) => (
  <section className="flex justify-between items-end mb-0.5">
    <div className="flex flex-col gap-1">
      <span className="text-xs text-neutral-400">
        <FormattedMessage {...messages.takenOn} />
      </span>
      {takenAt ? (
        <DateTime date={takenAt} />
      ) : (
        <span className="text-sm text-neutral-300">
          <FormattedMessage {...messages.dateNotAvailable} />
        </span>
      )}
    </div>
    <ByOperator id={takenBy} />
  </section>
);
