import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type DepositProps = {
  className?: string;
  payment: ContractPayment;
};

export const Deposit = ({ className, payment: { deposit } }: DepositProps) => {
  if (!deposit) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex justify-between my-2 gap-8 text-neutral-800">
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.deposit} />
        </span>
        <Amount {...deposit?.price} />
      </div>
    </div>
  );
};
