import { InputLabel } from '@cooltra/ui';
import { FormSelectField } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const FormBillingServicePointSelect = () => {
  const intl = useIntl();
  const { servicePointsOptions, isLoading } = useServicePointOptions();

  return (
    <FormSelectField
      name="billingServicePointId"
      id="billing-service-point-id"
      isLoading={isLoading}
      label={
        <InputLabel htmlFor="billing-service-point-id">
          <FormattedMessage {...messages.currentServicePoint} />
        </InputLabel>
      }
      options={servicePointsOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
