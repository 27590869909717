import { defineMessages } from 'react-intl';

export default defineMessages({
  takenOn: {
    defaultMessage: 'Taken on',
  },
  dateNotAvailable: {
    defaultMessage: 'Date not available',
  },
  by: {
    defaultMessage: 'by',
  },
});
