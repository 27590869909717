import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { ServicePointsRowLayout } from './ServicePointsRowLayout';
import messages from './messages';

export const ServicePointsHeader = () => (
  <ServicePointsRowLayout
    servicePoint={
      <ListColumnLabel>
        <FormattedMessage {...messages.servicePoint} />
      </ListColumnLabel>
    }
  />
);
