import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ServicePointsRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  servicePoint: ReactNode;
};

export const ServicePointsRowLayout = ({
  servicePoint,
  className,
  ...rest
}: ServicePointsRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="w-full shrink-0">{servicePoint}</div>
    </div>
  </div>
);
