import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon } from '@cooltra/ui';
import {
  FormNativeSelectField,
  FormButton,
  FormInputField,
} from '@cooltra/form';
import { MdClose, MdEuro } from 'react-icons/md';

import { FormAmountInputField } from '~/common';

import messages from './messages';

export type ManualAdjustmentProps = {
  onClose: () => void;
};

const MAX_LENGTH_DESCRIPTION = 100;
const id = 'subscription-contract-manual-adjustment-periodicity';

export const ManualAdjustment = ({ onClose }: ManualAdjustmentProps) => {
  const { formatMessage } = useIntl();

  const options = [
    {
      value: 'RECURRENT_CHARGE',
      label: formatMessage(messages.recurrentCharge),
    },
    {
      value: 'SINGLE_CHARGE',
      label: formatMessage(messages.singleCharge),
    },
  ];

  return (
    <div className="flex justify-end items-stretch gap-4">
      <FormInputField
        name="description"
        maxLength={MAX_LENGTH_DESCRIPTION}
        placeholder={formatMessage(messages.addManualAdjustment)}
        className="w-72"
      />
      <FormNativeSelectField
        className="w-52"
        id={id}
        data-testid={id}
        options={options}
        name="isRecurrent"
      />
      <FormAmountInputField
        name="value"
        placeholder="0.00"
        className="w-24"
        trailingIcon={<MdEuro />}
        allowNegative={true}
      />
      <FormButton disableInvalid>
        <FormattedMessage {...messages.addAdjustment} />
      </FormButton>
      <Button variant="neutral" emphasis="low" square={true} onClick={onClose}>
        <Icon aria-label={formatMessage(messages.close)} className="text-2xl">
          <MdClose />
        </Icon>
      </Button>
    </div>
  );
};
