import {
  useContractPaymentsQuery,
  useContractQuery,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { useParams } from 'react-router-dom';
import { isAfter } from 'date-fns';

import { isTestingEnv } from '~/utils/e2e';

import {
  isDriversDataComplete,
  isSecondDriverDataComplete,
  isTaxIdRequired,
} from './is-drivers-data-complete';
import { areChecksInCompleted, areChecksOutCompleted } from './checks';

export const useContractFlags = () => {
  const { contractId = '' } = useParams();

  const { data: servicePoints } = useServicePointsQuery(
    {},
    {
      enabled: isTestingEnv(),
    }
  );

  const { data: contract } = useContractQuery(contractId, {
    enabled: isTestingEnv(),
  });
  const { data: payments } = useContractPaymentsQuery(contractId, {
    enabled: isTestingEnv(),
  });

  if (!contract || !payments || !servicePoints) {
    return {
      pendingChecksIn: false,
      pendingChecksOut: false,
      areDetailsEditable: false,
      hasAssignedVehicle: false,
      hasBeenModifiedInEtendo: false,
      hasDepositPendingWithheld: false,
      hasDepositPendingRelease: false,
      hasDepositBeenWithheld: false,
      hasDepositBeenReleasedAutomatically: false,
      hasAwaitingPaymentTicket: false,
      hasPaidTicket: false,
      hasPendingTicket: false,
      isDriversDataComplete: false,
      hasDriversDataWarning: false,
      isSecondDriverDataComplete: false,
      isVehicleRemovable: false,
      pendingPayment: false,
      requiresDeposit: false,
      shouldReleaseDeposit: false,
      isTaxIdRequired: false,
      canSendEmail: false,
      canExtendContract: false,
      hasClickAndRide: false,
      isPendingSignature: false,
      isSignedAndAwaitingActivation: false,
      isSignedOrPendingSignature: false,
    };
  }
  const { status, vehicle } = contract;

  const hasDepositPendingWithheld =
    !!contract.deposit && !contract.deposit.withheldAt;

  const hasDepositBeenWithheld =
    !!contract.deposit &&
    !!contract.deposit.withheldAt &&
    !contract.deposit.releasedAt;

  const hasDepositExpired =
    !!contract.deposit?.withheldValidUntil &&
    isAfter(new Date(), new Date(contract.deposit.withheldValidUntil));

  const hasDepositPendingRelease =
    !!contract.deposit && !hasDepositExpired && !contract.deposit.releasedAt;

  const isTPVAutomaticallyReleased =
    contract.deposit?.method === 'TPV' &&
    !!contract.deposit.withheldValidUntil &&
    isAfter(new Date(), new Date(contract.deposit.withheldValidUntil));

  const isStripeAutomaticallyReleased =
    (contract.deposit?.method === 'STRIPE_QR' ||
      contract.deposit?.method === 'BOOKING_CARD') &&
    !!contract.deposit.withheldValidUntil &&
    (!!contract.deposit.releasedAt ||
      isAfter(new Date(), new Date(contract.deposit?.withheldValidUntil)));

  const hasDepositBeenReleasedAutomatically =
    !!contract.deposit &&
    !!contract.deposit.withheldAt &&
    (isTPVAutomaticallyReleased || isStripeAutomaticallyReleased);

  const requiresDeposit =
    !!contract.coverage?.deposit.value &&
    (status === 'BOOKED' || status === 'DRAFT');

  const shouldReleaseDeposit =
    hasDepositBeenWithheld &&
    !hasDepositBeenReleasedAutomatically &&
    (status === 'ACTIVE' || status === 'CLOSED');

  const hasPendingTicket = payments.some(({ status }) => status === 'PENDING');

  const hasPaidTicket = payments.some(({ status }) => status === 'PAID');

  const hasAwaitingPaymentTicket = payments.some(
    ({ status }) => status === 'AWAITING_PAYMENT'
  );

  const hasAssignedVehicle = !!contract.vehicle?.assignedVehicle;

  const hasBeenModifiedInEtendo = status === 'ACTIVE' && !hasAssignedVehicle;

  const pendingPayment = contract.pendingAmount.value > 0;

  const isEbike = !!vehicle && vehicle.category === 'EBIKE';

  const areDetailsEditable =
    status === 'DRAFT' || status === 'NEW' || status === 'BOOKED';

  const isVehicleRemovable =
    (status === 'DRAFT' || status === 'BOOKED') &&
    !hasPaidTicket &&
    !hasDepositBeenWithheld;

  const pendingChecksOut =
    status === 'ACTIVE' && !areChecksOutCompleted(contract, isEbike);

  const pendingChecksIn = !areChecksInCompleted(contract, isEbike);

  const servicePointCountry = servicePoints?.find(
    ({ id }) => id === contract.details?.servicePointId
  )?.country;

  const canSendEmail =
    (status === 'DRAFT' || status === 'BOOKED') &&
    !!vehicle?.vehicleTypeId &&
    !!contract.user?.email;

  const canExtendContract = status === 'ACTIVE';

  const hasClickAndRide = contract.hasClickAndRide;

  const isPendingSignature =
    contract.documents?.some((contract) => contract.status === 'SENT') &&
    contract.status === 'BOOKED';

  const isSignedAndAwaitingActivation =
    contract.documents?.some((contract) => contract.status === 'SIGNED') &&
    contract.status === 'BOOKED';

  const isSignedOrPendingSignature =
    isPendingSignature || isSignedAndAwaitingActivation;

  return {
    pendingChecksIn,
    pendingChecksOut,
    areDetailsEditable,
    hasAssignedVehicle,
    hasBeenModifiedInEtendo,
    hasDepositPendingWithheld,
    hasDepositPendingRelease,
    hasDepositBeenWithheld,
    hasDepositBeenReleasedAutomatically,
    hasAwaitingPaymentTicket,
    hasPaidTicket,
    hasPendingTicket,
    hasClickAndRide,
    isDriversDataComplete: isDriversDataComplete(contract, servicePointCountry),
    isSecondDriverDataComplete: isSecondDriverDataComplete(
      contract.secondDriver
    ),
    hasDriversDataWarning:
      !isDriversDataComplete(contract, servicePointCountry) &&
      !['ACTIVE', 'CLOSED'].includes(status),
    isVehicleRemovable,
    pendingPayment,
    requiresDeposit,
    shouldReleaseDeposit,
    isTaxIdRequired: isTaxIdRequired({
      servicePointCountry,
      taxIdCountry: contract.user?.taxIdCountry,
    }),
    canSendEmail,
    canExtendContract,
    isPendingSignature,
    isSignedAndAwaitingActivation,
    isSignedOrPendingSignature,
  };
};
