import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { useAddManualAdjustmentToContractMutation } from '@cooltra/station-based-api';
import { useParams } from 'react-router-dom';

import { useNotification } from '~/hooks';

import { ManualAdjustment } from './ManualAdjustment';
import {
  ManualAdjustmentValues,
  manualAdjustmentInitialFormValues,
  validateManualAdjustmentForm,
} from './manual-adjustment-form';
import messages from './messages';

export type ManualAdjustmentFormProps = {
  close: VoidFunction;
};

export const ManualAdjustmentForm = ({ close }: ManualAdjustmentFormProps) => {
  const intl = useIntl();
  const { contractId = '' } = useParams();

  const { mutateAsync } = useAddManualAdjustmentToContractMutation(contractId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const handleOnSubmit = (
    values: ManualAdjustmentValues,
    { resetForm }: FormHelpers<ManualAdjustmentValues>
  ) => {
    return mutateAsync({
      description: values.description,
      value: Number(values.value),
      isRecurrent: values.isRecurrent == 'RECURRENT_CHARGE',
    })
      .then(() => {
        close();
        addSuccessNotification(
          intl.formatMessage(messages.successNotification)
        );
        resetForm(manualAdjustmentInitialFormValues);
      })
      .catch(({ response }: AxiosError) =>
        addErrorNotification(
          response?.status === 400
            ? intl.formatMessage(messages.priceGreaterThanTotalPrice)
            : undefined
        )
      );
  };

  return (
    <FormProvider
      initialValues={manualAdjustmentInitialFormValues}
      onSubmit={handleOnSubmit}
      validate={validateManualAdjustmentForm(intl)}
    >
      <Form>
        <ManualAdjustment onClose={close} />
      </Form>
    </FormProvider>
  );
};
