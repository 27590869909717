import {
  Contract,
  DepositMethod,
  useContractDepositMutation,
  useContractPendingDepositMutation,
  useContractQuery,
} from '@cooltra/station-based-api';
import {
  Form,
  FormButton,
  FormContext,
  FormRadioGroupCustomField,
  useForm,
} from '@cooltra/form';
import { FormattedMessage } from 'react-intl';
import { MdEuro } from 'react-icons/md';
import { Button, Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import {
  DepositCard,
  DepositCardHeading,
  DepositMethodOption,
  FormAmountInputField,
  FormBillingServicePointSelect,
  NoDeposit,
  PendingDeposit,
  QRPayment,
  ReleasedDeposit,
} from '~/common';
import {
  useNotification,
  usePolling,
  useSubscriptionContractFlags,
} from '~/hooks';

import { ChargedDeposit } from '../ChargedDeposit/ChargedDeposit';

import messages from './messages';

type SubscriptionDepositFormValues = {
  amount: string;
  method?: DepositMethod;
  billingServicePointId: string;
};

export type SubscriptionContractDepositProps = {
  contract: Contract;
};

export const SubscriptionContractDeposit = ({
  contract,
}: SubscriptionContractDepositProps) => {
  const {
    contractId,
    coverage,
    hasLinkedPaymentMethod,
    details,
    deposit,
    status,
  } = contract;
  const {
    requiresDeposit,
    hasDepositBeenCharged,
    hasDepositPendingCharge,
    isSignedOrPendingSignature,
  } = useSubscriptionContractFlags();
  const { addErrorNotification } = useNotification();

  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();
  const [isQRShown, { toggleOff: hideQR, toggleOn: showQR }] = useToggle();

  const initialValues: SubscriptionDepositFormValues = {
    amount: coverage?.deposit?.value.toString() || '',
    method: hasLinkedPaymentMethod ? 'BOOKING_CARD' : 'STRIPE_QR',
    billingServicePointId: details!.servicePointId,
  };

  const depositMutation = useContractDepositMutation(contractId);
  const pendingDepositMutation = useContractPendingDepositMutation(contractId);

  const onSubmit = async ({
    amount,
    method,
    billingServicePointId,
  }: SubscriptionDepositFormValues) => {
    if (!method) {
      return;
    }

    if (method === 'BOOKING_CARD') {
      return depositMutation
        .mutateAsync({
          amount: Number(amount),
          method,
          operationNumber: null,
          lastFourDigits: null,
          isCharge: true,
          billingServicePointId: billingServicePointId,
        })
        .then(() => {})
        .catch(() => addErrorNotification());
    }

    if (method === 'STRIPE_QR') {
      return pendingDepositMutation
        .mutateAsync({
          amount: Number(amount),
        })
        .then(() => showQR())
        .catch(() => addErrorNotification());
    }
  };

  const form = useForm({
    initialValues,
    onSubmit,
  });

  const { refetch } = useContractQuery(contractId, {
    enabled: false,
  });
  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      toggleOff();
      depositMutation.reset();
      form.resetForm({
        amount: coverage?.deposit?.value.toString() || '',
        method: 'BOOKING_CARD',
        billingServicePointId: details!.servicePointId,
      });
    },
    interval:
      depositMutation.isSuccess && (!deposit || deposit.releasedAt) ? 2000 : 0,
  });

  if (status === 'NO_SHOW') {
    return <NoDeposit />;
  }

  if (
    (status === 'ACTIVE' || status === 'CLOSED') &&
    !coverage?.deposit.value &&
    !deposit
  ) {
    return <NoDeposit />;
  }

  if ((status === 'ACTIVE' || status === 'CLOSED') && !!deposit?.releasedAt) {
    return <ReleasedDeposit deposit={deposit} />;
  }

  return (
    <DepositCard>
      <FormContext.Provider value={form}>
        <div className="flex flex-row w-full justify-between items-center mb-6">
          <DepositCardHeading
            warning={requiresDeposit && !hasDepositBeenCharged}
          />
          {!hasDepositBeenCharged && !hasDepositPendingCharge && (
            <FormAmountInputField
              name="amount"
              decimalScale={0}
              className="w-24"
              data-testid="DEPOSIT_AMOUNT_FIELD"
              trailingIcon={<MdEuro />}
            />
          )}
        </div>
        {hasDepositPendingCharge && deposit && (
          <PendingDeposit contractId={contractId} deposit={deposit} />
        )}
        {hasDepositBeenCharged && deposit && (
          <ChargedDeposit
            contractId={contractId}
            contractStatus={status}
            deposit={deposit}
          />
        )}
        {!hasDepositBeenCharged && !hasDepositPendingCharge && (
          <div className="flex justify-end mt-6">
            <Button
              emphasis="high"
              onClick={toggleOn}
              className="self-end"
              disabled={!form.values.amount || isSignedOrPendingSignature}
            >
              <FormattedMessage {...messages.chargeDeposit} />
            </Button>
          </div>
        )}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleOff}
          onAfterClose={hideQR}
        >
          <Form>
            {isQRShown ? (
              <QRPayment
                className="px-20 pb-12"
                id={deposit?.id || ''}
                isDeposit={true}
              />
            ) : (
              <div className="min-w-xl px-24 pb-12 flex flex-col gap-2 items-center">
                <FormRadioGroupCustomField
                  className="w-full"
                  id="payment-method"
                  name="method"
                  legend={
                    <legend className="block text-xl font-semibold text-neutral-700 mb-8 text-center">
                      <FormattedMessage {...messages.legend} />
                    </legend>
                  }
                  items={(hasLinkedPaymentMethod
                    ? ['BOOKING_CARD', 'STRIPE_QR', 'EMAIL']
                    : ['STRIPE_QR', 'EMAIL']
                  ).map((method) => ({
                    label: '',
                    value: method,
                  }))}
                  renderRadio={({ checked, value }) => (
                    <DepositMethodOption
                      type={value as DepositMethod}
                      checked={checked}
                    />
                  )}
                />
                <div className="pt-4 w-full mb-8">
                  <FormBillingServicePointSelect />
                </div>
                <FormButton disablePristine={false} loading={isPolling}>
                  {form.values.method === 'STRIPE_QR' ? (
                    <FormattedMessage {...messages.seeQR} />
                  ) : (
                    <FormattedMessage
                      {...messages.confirm}
                      values={{
                        amount: form.values.amount,
                      }}
                    />
                  )}
                </FormButton>
              </div>
            )}
          </Form>
        </Modal>
      </FormContext.Provider>
    </DepositCard>
  );
};
