import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '../../List';
import { InvoiceRowLayout } from '../InvoiceRowLayout';

import messages from './messages';

export const InvoicesListHeader = () => (
  <InvoiceRowLayout
    issuedAt={
      <ListColumnLabel>
        <FormattedMessage {...messages.issuedAt} />
      </ListColumnLabel>
    }
    type={
      <ListColumnLabel>
        <FormattedMessage {...messages.type} />
      </ListColumnLabel>
    }
    invoiceNumber={
      <ListColumnLabel>
        <FormattedMessage {...messages.invoiceNumber} />
      </ListColumnLabel>
    }
    legalEntity={
      <ListColumnLabel>
        <FormattedMessage {...messages.legalEntity} />
      </ListColumnLabel>
    }
    total={
      <ListColumnLabel>
        <FormattedMessage {...messages.total} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
    invoicePdf={<span />}
  />
);
