import { defineMessages } from 'react-intl';

export default defineMessages({
  adjustTotal: {
    defaultMessage: 'Adjust total',
  },
  negativeValue: {
    defaultMessage: 'Negative value (–)',
  },
  addManualAdjustment: {
    defaultMessage: 'Add manual adjustment',
  },
  addAdjustment: {
    defaultMessage: 'Add',
  },
  successNotification: {
    defaultMessage: 'Adjustment added successfully',
  },
  close: {
    defaultMessage: 'Close',
  },
  priceGreaterThanTotalPrice: {
    defaultMessage:
      'The price adjustment cannot be greater than the total price',
  },
  singleCharge: {
    defaultMessage: 'Single charge',
  },
  recurrentCharge: {
    defaultMessage: 'Recurrent charge',
  },
});
