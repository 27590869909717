import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type TotalProps = {
  className?: string;
  payment: ContractPayment;
};

export const Total = ({ className, payment }: TotalProps) => (
  <div
    className={classNames('flex flex-col gap-2 text-neutral-800', className)}
  >
    {payment.totalOriginalPrice && !payment.deposit && (
      <div className="w-full flex justify-between my-2 gap-8">
        <FormattedMessage {...messages.totalOriginalPrice} tagName="span" />
        <Amount {...payment.totalOriginalPrice} />
      </div>
    )}
    {payment.couponDiscount && (
      <div className="w-full flex justify-between my-2 gap-8">
        <FormattedMessage {...messages.couponDiscount} tagName="span" />
        <Amount {...payment.couponDiscount} />
      </div>
    )}
    <div className="flex justify-between gap-8">
      <h3 className="text-xl">
        <FormattedMessage {...messages.total} />
      </h3>
      <span className="text-xl font-semibold">
        <Amount {...payment.totalPrice} />
      </span>
    </div>
  </div>
);
