import { Contract } from '@cooltra/station-based-api';
import { IntlShape } from 'react-intl';
import { addHours, format } from 'date-fns';

import { createSetError, errorMessages, validateDate } from '~/validation';
import { getContractTypeName } from '~/utils/contract';
import { dateFormat } from '~/utils/date';

export type SubscriptionContractDetailsFormValues = {
  type: string;
  pickup: string;
  pickUpDate: string;
  pickUpTime: string;
};

export type SubscriptionContractDetailsFormFieldName =
  keyof SubscriptionContractDetailsFormValues;

type SubscriptionContractDetailsFormErrors = Partial<
  Record<SubscriptionContractDetailsFormFieldName, string | undefined>
>;

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' || value === undefined
    ? intl.formatMessage(errorMessages.required)
    : undefined;

export const validateSubscriptionContractDetailsForm =
  (intl: IntlShape) =>
  (
    values: SubscriptionContractDetailsFormValues
  ): SubscriptionContractDetailsFormErrors => {
    const errors: SubscriptionContractDetailsFormErrors = {};
    const setError = createSetError(errors);

    const requiredFields: SubscriptionContractDetailsFormFieldName[] = [
      'pickup',
      'type',
      'pickUpTime',
    ];
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    setError('pickUpDate', validateDate(intl, values.pickUpDate));

    return errors;
  };

export const getInitialSubscriptionContractDetailsFormValues = (
  contract: Contract,
  preferredServicePointId: string | undefined
): SubscriptionContractDetailsFormValues => {
  const { details, type } = contract;

  if (!details) {
    return {
      type: '',
      pickup: preferredServicePointId || '',
      pickUpDate: format(new Date(), dateFormat),
      pickUpTime: format(addHours(new Date(), 1), 'HH:00'),
    };
  }

  return {
    type: getContractTypeName(type),
    pickup: details.servicePointId,
    pickUpDate: format(details.startDate, dateFormat),
    pickUpTime: details.startTime,
  };
};
