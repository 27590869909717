import { useState } from 'react';
import {
  useContractPaymentsQuery,
  ContractPayment,
} from '@cooltra/station-based-api';

import { usePolling } from '~/hooks';

export type UsePayTicketPollingProps = {
  contractId: string;
  ticketId: string;
  onSettle: () => void;
};

const getIsPaymentPaid = (
  contractPayments: ContractPayment[] | undefined,
  ticketId: string
) => {
  const ticket = contractPayments?.find((t) => t.id === ticketId);
  return Boolean(ticket?.status === 'PAID');
};

export const usePayTicketPolling = ({
  onSettle,
  contractId,
  ticketId,
}: UsePayTicketPollingProps) => {
  const [isStripePollingActive, setIsStripePollingActive] = useState(false);
  const { data: contractPayments, refetch: refetchContractPayments } =
    useContractPaymentsQuery(contractId);

  const isPaymentPaid = getIsPaymentPaid(contractPayments, ticketId);

  const { isPolling } = usePolling({
    fn: refetchContractPayments,
    onSettle: onSettle,
    maxAttempts: 5,
    interval: !isPaymentPaid && isStripePollingActive ? 500 : 0,
  });

  return {
    isPolling,
    setIsStripePollingActive,
  };
};
