import { List } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ServicePoint } from '@cooltra/station-based-api';

import { NoSearchResults } from '~/common';

import { ServicePointsHeader } from './ServicePointsHeader';
import { ServicePointsCardLoading } from './ServicePointsCardLoading';
import { ServicePointsCard } from './ServicePointsCard';

type ServicePointsProps = {
  servicePoints: ServicePoint[];
  isLoading: boolean;
};

export const ServicePoints = ({
  servicePoints,
  isLoading,
}: ServicePointsProps) => (
  <div
    data-testid="SERVICE_POINTS"
    className={classNames('container min-w-3xl max-w-6xl py-12', 'pb-48')}
  >
    <List
      loadingRows={servicePoints.length}
      isLoading={isLoading}
      idProp="id"
      data={servicePoints}
      renderEmptyMessage={<NoSearchResults />}
      renderHeader={<ServicePointsHeader />}
      renderLoadingRow={<ServicePointsCardLoading />}
      renderRow={(servicePoint) => <ServicePointsCard {...servicePoint} />}
    />
  </div>
);
