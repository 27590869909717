import { Money } from '@cooltra/utils';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';

import { Amount } from '~/common';

import messages from './messages';

const AmountText = (props: PropsWithChildren) => (
  <span className="text-neutral-800 text-xl font-semibold" {...props} />
);

export const MonthlyCharge = ({ amount }: { amount: Money | null }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <span className="text-neutral-500 text-xs">
        {intl.formatMessage(messages.monthlyCharge)}
      </span>
      <span className="text-neutral-800 text-xl font-semibold">
        <span className="flex items-center gap-1">
          {amount ? (
            <Amount value={amount!.value} currency={amount!.currency} />
          ) : (
            <AmountText>-</AmountText>
          )}
        </span>
      </span>
    </div>
  );
};
