import { useIntl } from 'react-intl';
import { FormProvider } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import {
  useContractDepositMutation,
  Contract,
} from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { MdEuro } from 'react-icons/md';

import { useContractFlags, useNotification } from '~/hooks';
import {
  DepositCard,
  DepositCardHeading,
  FormAmountInputField,
  NoDeposit,
  PendingDeposit,
  ReleasedDeposit,
} from '~/common';

import { ContractDepositModal } from '../ContractDepositModal/ContractDepositModal';
import { DepositFormValues, validateDepositForm } from '../deposit-form';
import { WithheldDeposit } from '../WithheldDeposit/WithheldDeposit';

import { useContractPolling } from './useContractPolling';
import messages from './messages';

export type ContractDepositProps = {
  contract: Contract;
};

export const ContractDeposit = ({
  contract: {
    contractId,
    coverage,
    deposit,
    hasLinkedPaymentMethod,
    status,
    details,
  },
}: ContractDepositProps) => {
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { mutateAsync, reset, isPending } =
    useContractDepositMutation(contractId);

  const { isPolling, setIsStripePollingActive } = useContractPolling({
    onSettle: () => {
      reset();
      toggleOff();
    },
    contractId,
  });

  const initialValues: DepositFormValues = {
    amount: coverage?.deposit?.value ? coverage.deposit.value.toString() : '',
    operationNumber: '',
    lastFourDigits: '',
    method: hasLinkedPaymentMethod ? undefined : 'TPV',
    isCharge: false,
    billingServicePointId: details!.servicePointId,
  };

  const handleOnSubmit = (
    {
      amount,
      method,
      operationNumber,
      lastFourDigits,
      isCharge,
      billingServicePointId,
    }: DepositFormValues,
    resetForm: (values: DepositFormValues) => void
  ) => {
    if (!method) {
      return Promise.reject();
    }

    return mutateAsync({
      amount: Number(amount),
      method,
      operationNumber: method === 'TPV' ? operationNumber : null,
      lastFourDigits: method === 'TPV' ? lastFourDigits : null,
      isCharge,
      billingServicePointId:
        method === 'TPV' && isCharge ? billingServicePointId : null,
    })
      .then(() => {
        if (method === 'BOOKING_CARD') {
          setIsStripePollingActive(true);
          return;
        }
        toggleOff();
        addSuccessNotification(
          intl.formatMessage(messages.successNotification)
        );
        resetForm(initialValues);
      })
      .catch((error) => {
        const errorStatus = getErrorStatus(error);
        addErrorNotification(
          errorStatus && errorStatus < 500 && method === 'BOOKING_CARD'
            ? intl.formatMessage(messages.stripeDepositFailed)
            : undefined
        );
      });
  };

  const {
    hasDepositBeenWithheld,
    hasDepositPendingWithheld,
    requiresDeposit,
    shouldReleaseDeposit,
    isSignedOrPendingSignature,
  } = useContractFlags();

  const isLoading = isPolling || isPending;

  if (status === 'NO_SHOW') {
    return <NoDeposit />;
  }

  if (
    (status === 'ACTIVE' || status === 'CLOSED') &&
    !coverage?.deposit.value &&
    !deposit
  ) {
    return <NoDeposit />;
  }

  if ((status === 'ACTIVE' || status === 'CLOSED') && !!deposit?.releasedAt) {
    return <ReleasedDeposit deposit={deposit} />;
  }

  return (
    <DepositCard>
      <FormProvider
        initialValues={initialValues}
        validate={validateDepositForm(intl)}
      >
        <div className="flex flex-row w-full justify-between items-center mb-6">
          <DepositCardHeading
            warning={
              (requiresDeposit && !hasDepositBeenWithheld) ||
              shouldReleaseDeposit
            }
          />
          {!hasDepositBeenWithheld && !hasDepositPendingWithheld && (
            <FormAmountInputField
              name="amount"
              data-testid="DEPOSIT_AMOUNT_FIELD"
              decimalScale={0}
              className="w-24"
              trailingIcon={<MdEuro />}
              disabled={isSignedOrPendingSignature || isLoading}
            />
          )}
        </div>
        {hasDepositPendingWithheld && deposit && (
          <PendingDeposit contractId={contractId} deposit={deposit} />
        )}
        {hasDepositBeenWithheld && deposit && (
          <WithheldDeposit
            contractId={contractId}
            contractStatus={status}
            deposit={deposit}
          />
        )}
        {!hasDepositBeenWithheld && !hasDepositPendingWithheld && (
          <ContractDepositModal
            contractId={contractId}
            hasLinkedPaymentMethod={hasLinkedPaymentMethod}
            isLoading={isLoading}
            isOpen={isOpen}
            onClose={toggleOff}
            onOpen={toggleOn}
            onSubmit={handleOnSubmit}
          />
        )}
      </FormProvider>
    </DepositCard>
  );
};
