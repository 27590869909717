import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  ContractStatus,
  useContractPaymentsQuery,
  useRefundTicketContractMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';

import { useNotification, usePolling } from '~/hooks';

import { Amount } from '../../../Amount/Amount';
import { ConfirmModal } from '../../../ConfirmModal/ConfirmModal';

import messages from './messages';

export type RefundButtonProps = {
  contractId: string;
  contractStatus: ContractStatus;
  payment: ContractPayment;
  disabled?: boolean;
};

export const RefundButton = ({
  disabled = false,
  contractId,
  contractStatus,
  payment: {
    id,
    status,
    totalPrice: { currency, value },
    paymentMethod,
    operationNumber,
    lastFourDigits,
    deposit,
  },
}: RefundButtonProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { mutateAsync, reset, isPending, isSuccess, error } =
    useRefundTicketContractMutation(contractId, id);
  const { addErrorNotification } = useNotification();
  const queryClient = useQueryClient();

  const { data: payments = [], refetch } = useContractPaymentsQuery(contractId);

  const getErrorMessage = (error: AxiosError) =>
    getErrorStatus(error) === 400
      ? formatMessage(messages.refundValidationError)
      : undefined;

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      reset();
      queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
      if (error) {
        addErrorNotification(getErrorMessage(error));
      } else {
        toggleOff();
      }
    },
    maxAttempts: 2,
    interval:
      paymentMethod === 'CARD' &&
      isSuccess &&
      payments.find((ticket) => ticket.id === id)?.status !== 'REFUNDED'
        ? 1000
        : 0,
  });

  const handleRefund = () => {
    if (paymentMethod === 'CARD') {
      mutateAsync().catch((error) =>
        addErrorNotification(getErrorMessage(error))
      );
    } else {
      mutateAsync()
        .then(() => toggleOff())
        .catch((error) => addErrorNotification(getErrorMessage(error)));
    }
  };

  const isRefundButtonVisible =
    (contractStatus === 'DRAFT' || contractStatus === 'BOOKED') && !deposit;

  return (
    <>
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="REFUND_CONTRACT_TICKET_CONFIRM_MODAL"
        isOpen={isOpen}
        title={formatMessage(messages.areYourSureToRefundTicket)}
        content={
          paymentMethod === 'TPV' ? (
            <div className="mb-5">
              <FormattedMessage
                {...messages.tpvOperationId}
                tagName="p"
                values={{
                  operationId: operationNumber,
                }}
              />
              <FormattedMessage
                {...messages.cardLastFourDigits}
                tagName="p"
                values={{
                  lastFourDigits: lastFourDigits,
                }}
              />
            </div>
          ) : (
            <></>
          )
        }
        confirmText={formatMessage(messages.confirmRefund, {
          amount: <Amount value={value} currency={currency} />,
        })}
        onClose={toggleOff}
        onConfirm={handleRefund}
        loading={isPending || isPolling}
      />
      {hasPermission('cancel:station_based_contracts') &&
        isRefundButtonVisible &&
        status === 'PAID' && (
          <Button
            size="sm"
            disabled={disabled || isPending || isPolling}
            onClick={toggleOn}
          >
            <FormattedMessage {...messages.refund} />
          </Button>
        )}
      {status === 'REFUNDED' && (
        <span className="text-sm text-success-500">
          <FormattedMessage {...messages.refunded} />
        </span>
      )}
    </>
  );
};
