import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { OptionalFieldLabel } from '../../OptionalFieldLabel/OptionalFieldLabel';
import { FormDateInputField } from '../../FormattedInput';

import messages from './messages';

export type ExpirationDateProps = {
  optional?: boolean;
};

export const ExpirationDate = ({ optional = false }: ExpirationDateProps) => (
  <FormDateInputField
    id="driver-license-expiration-date"
    label={
      optional ? (
        <OptionalFieldLabel htmlFor="driver-license-expiration-date">
          <FormattedMessage {...messages.driverLicenseExpirationDate} />
        </OptionalFieldLabel>
      ) : (
        <InputLabel htmlFor="driver-license-expiration-date">
          <FormattedMessage {...messages.driverLicenseExpirationDate} />
        </InputLabel>
      )
    }
    name="driverLicenseExpirationDate"
  />
);
