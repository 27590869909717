import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';

import { useAccessoryName } from '~/hooks';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type AccessoriesProps = {
  className?: string;
  payment: ContractPayment;
};

export const Accessories = ({
  className,
  payment: { accessories },
}: AccessoriesProps) => {
  const getAccessoryName = useAccessoryName();

  if (!accessories?.length) {
    return null;
  }

  return (
    <div className={className}>
      <h3 className="text-sm text-neutral-800">
        <FormattedMessage {...messages.accessories} />
      </h3>
      <div>
        {accessories.map(({ id, days, name, quantity, price }) => (
          <div
            key={id}
            className="flex justify-between my-2 gap-8 text-neutral-800"
          >
            <div className="flex gap-1 items-center">
              <span>
                {days ? (
                  <FormattedMessage
                    {...messages.days}
                    values={{
                      name: getAccessoryName(name),
                      contractDays: days,
                    }}
                  />
                ) : (
                  getAccessoryName(name)
                )}
              </span>
              <span>{quantity > 1 ? 'x' + quantity : ''}</span>
            </div>
            <Amount {...price} />
          </div>
        ))}
      </div>
    </div>
  );
};
