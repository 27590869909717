import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useContractQuery, Contract } from '@cooltra/station-based-api';

import { useNotification, usePolling, usePrevious } from '~/hooks';

import messages from './messages';

export type UseContractPollingProps = {
  contractId: string;
  onSettle: () => void;
};

const getIsBookingCardDepositWithheld = (contract: Contract | undefined) =>
  contract?.deposit?.method === 'BOOKING_CARD' &&
  !contract?.deposit?.releasedAt;

export const useContractPolling = ({
  onSettle,
  contractId,
}: UseContractPollingProps) => {
  const { formatMessage } = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [isStripePollingActive, setIsStripePollingActive] = useState(false);
  const { data: contract, refetch } = useContractQuery(contractId);

  const isBookingCardDepositWithheld =
    getIsBookingCardDepositWithheld(contract);

  const isBookingCardDepositWithheldPrevious = usePrevious(
    isBookingCardDepositWithheld
  );
  const bookingDepositHasBeenWithheld =
    isBookingCardDepositWithheld &&
    isBookingCardDepositWithheldPrevious !== undefined &&
    !isBookingCardDepositWithheldPrevious;

  const checkIfDepositIsWithheld = () =>
    refetch().then(({ data }) => {
      if (getIsBookingCardDepositWithheld(data)) {
        addSuccessNotification(formatMessage(messages.successNotification));
        setIsStripePollingActive(false);
      }
    });

  const { isPolling } = usePolling({
    fn: checkIfDepositIsWithheld,
    onSettle: () => {
      if (!getIsBookingCardDepositWithheld(contract)) {
        addErrorNotification();
        setIsStripePollingActive(false);
      }

      onSettle();
    },
    maxAttempts: 5,
    interval: bookingDepositHasBeenWithheld || isStripePollingActive ? 500 : 0,
  });

  return {
    isPolling,
    setIsStripePollingActive,
  };
};
