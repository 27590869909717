import { Placeholder } from '@cooltra/ui';
import { useIntl } from 'react-intl';
import {
  System,
  useOperatorsQuery,
  Operator as OperatorAPI,
} from '@cooltra/auth-api';
import { classNames } from '@cooltra/utils';
import { MdLocationPin } from 'react-icons/md';

import { operatorGroups } from '~/utils/operators';

import { useSystemLabel } from '../../../System';

import messages from './messages';

export type ShiftOperatorProps = {
  operatorId: string;
  homeSystem: System | undefined | null;
  homeSystems: System[];
  operatorClassName?: string;
  roleClassName?: string;
};

export const ShiftOperator = ({
  operatorId,
  homeSystems,
  operatorClassName,
  roleClassName,
}: ShiftOperatorProps) => {
  const { data, isLoading } = useOperatorsQuery();
  const { formatMessage } = useIntl();
  const { getSystemLabel } = useSystemLabel();

  const getOperator = (): OperatorAPI | undefined =>
    data?.find(({ externalId }) => operatorId === externalId);

  const getOperatorGroup = (operatorGroupId: string | undefined) => {
    if (!operatorGroupId) {
      return formatMessage(messages.operatorGroupNotFound);
    }

    return Object.values(operatorGroups).find(
      ({ operatorGroupId: operatorGroupIdToCompare }) =>
        operatorGroupIdToCompare === operatorGroupId
    )?.name;
  };

  return isLoading ? (
    <Placeholder className="w-16 h-2" />
  ) : (
    <div>
      <span
        className={classNames(
          'block text-2xl font-semibold text-neutral-1000 capitalize',
          operatorClassName
        )}
      >
        {getOperator()?.name}
      </span>
      <span
        className={classNames(
          'block text-sm mt-2 text-neutral-1000',
          roleClassName
        )}
      >
        {getOperatorGroup(getOperator()?.operatorGroupIds[0])}
      </span>
      <span
        className={classNames(
          'block text-sm mt-2 text-neutral-1000',
          roleClassName
        )}
      >
        <div className="flex items-center">
          <MdLocationPin className="text-neutral-400 flex-none mr-1 shrink-0" />
          <span>{homeSystems.map(getSystemLabel).join(', ')}</span>
        </div>
      </span>
    </div>
  );
};
