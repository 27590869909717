import { RouterCardLink } from '@cooltra/navigation';
import { PropsWithChildren } from 'react';

export type ContractCardRouterLinkProps = PropsWithChildren<{
  id: string;
  isSubscription: boolean;
}>;

export const ContractCardRouterLink = ({
  children,
  id,
  isSubscription,
  ...rest
}: ContractCardRouterLinkProps) => (
  <RouterCardLink
    data-testid={id}
    to={
      isSubscription
        ? `/contracts/subscription/${id}/overview`
        : `/contracts/short-term/${id}/overview`
    }
    className="w-full overflow-hidden"
    {...rest}
  >
    {children}
  </RouterCardLink>
);
