import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    defaultMessage: 'Home',
  },
  users: {
    defaultMessage: 'Users',
  },
  locations: {
    defaultMessage: 'Locations',
  },
  account: {
    defaultMessage: 'Account',
  },
  logout: {
    defaultMessage: 'Log out',
  },
  settings: {
    defaultMessage: 'Go to Settings',
  },
  vehicles: {
    defaultMessage: 'Vehicles',
  },
  tasks: {
    defaultMessage: 'Tasks',
  },
  rentals: {
    defaultMessage: 'Rentals',
  },
  problemReports: {
    defaultMessage: 'Problem reports',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
  shifts: {
    defaultMessage: 'Shifts',
  },
  offers: {
    defaultMessage: 'Offers',
  },
  zones: {
    defaultMessage: 'Zones',
  },
  stationBased: {
    defaultMessage: 'Station Based',
  },
});
