import { FormattedMessage } from 'react-intl';
import { MdArrowBack } from 'react-icons/md';
import { Badge, Link } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { Vehicle } from '@cooltra/station-based-api';

import { ClickAndRideLogo, TextSeparator } from '~/common';

import messages from './messages';

type VehicleDetailsNavbarProps = {
  vehicle: Vehicle;
};

export const VehicleDetailsNavbar = ({
  vehicle: { licensePlate, modelName, contract },
}: VehicleDetailsNavbarProps) => (
  <>
    <div className="flex items-center">
      <RouterLink to="/pos/vehicles" className="text-2xl p-1 mr-2">
        <MdArrowBack />
      </RouterLink>
      <span className="font-semibold text-2xl text-neutral-800 mr-4">
        {licensePlate}
      </span>
      <span className="text-sm text-neutral-600 mr-4">{modelName}</span>
      <Badge variant={contract ? 'warning' : 'primary'}>
        {contract ? (
          <FormattedMessage {...messages.assigned} />
        ) : (
          <FormattedMessage {...messages.available} />
        )}
      </Badge>
    </div>
    {!!contract && (
      <div className="flex items-center gap-3">
        <span className="text-sm text-neutral-800">
          <FormattedMessage
            {...messages.assignedToContract}
            values={{
              link: (
                <Link
                  href={`/contracts/short-term/${contract.contractId}/overview`}
                >
                  {contract.contractNumber}
                </Link>
              ),
            }}
          />
        </span>
        {contract.hasClickAndRide && (
          <>
            <TextSeparator />
            <ClickAndRideLogo width={84} height={16} />
          </>
        )}
      </div>
    )}
  </>
);
