import { FormInputField, useFormContext } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFormValues } from '../../Form';
import { OptionalFieldLabel } from '../../../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export const CompanyFiscalName = () => {
  const {
    values: { editMode },
  } = useFormContext<CooltraForEmployeesFormValues>();

  return (
    <FormInputField
      name="companyFiscalName"
      id="companyFiscalName"
      disabled={editMode}
      label={
        <OptionalFieldLabel htmlFor="companyFiscalName">
          <FormattedMessage {...messages.companyFiscalName} />
        </OptionalFieldLabel>
      }
    />
  );
};
