import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Choose deposit mode',
  },
  tpv: {
    defaultMessage: 'TPV La Caixa',
  },
  webPayment: {
    defaultMessage: 'Stripe card',
  },
  seeQR: {
    defaultMessage: 'See Stripe QR code',
  },
  confirm: {
    defaultMessage: 'Confirm deposit of €{amount}',
  },
  markAsRetention: {
    defaultMessage: 'Mark €{amount} as retained',
  },
  markAsCharged: {
    defaultMessage: 'Mark €{amount} as charged',
  },
  retainDeposit: {
    defaultMessage: 'Retain deposit',
  },
  operationNumber: {
    defaultMessage: 'Operation number',
  },
  lastFourDigits: {
    defaultMessage: 'Last 4 digits of card',
  },
});
