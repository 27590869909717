import { InputLabel, NativeSelectField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEvent, useState } from 'react';
import { useSubscriptionTermUpdateMutation } from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import messages from './messages';

const id = 'subscription-contract-term';

export type SubscriptionContractTermFieldProps = {
  initialValue: number;
  contractId: string;
  disabled: boolean;
};

export const SubscriptionContractTermField = ({
  initialValue,
  contractId,
  disabled,
}: SubscriptionContractTermFieldProps) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(initialValue || 0);

  const { mutateAsync } = useSubscriptionTermUpdateMutation(contractId);
  const { addErrorNotification } = useNotification();

  const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const monthsTerm = Number(target.value);
    setValue(monthsTerm);
    mutateAsync({ monthsTerm }).catch(() => {
      setValue(initialValue || 0);
      addErrorNotification();
    });
  };

  const options = [1, 6, 12].map((num) => ({
    value: num.toString(),
    label: formatMessage(messages.months, { months: num }),
  }));

  return (
    <NativeSelectField
      className="w-72"
      id={id}
      value={value}
      onChange={handleChange}
      label={
        <InputLabel htmlFor={id}>
          <FormattedMessage {...messages.term} />
        </InputLabel>
      }
      options={options}
      disabled={disabled}
    />
  );
};
