import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useContractPendingDepositMutation } from '@cooltra/station-based-api';

import { useQRPayment } from '~/libs/qr-payment';

import { DepositFormValues } from '../../deposit-form';
import messages from '../messages';

export type StripeQRButtonProps = {
  contractId: string;
  amount: string;
  onCloseModal: () => void;
};

export const StripeQRButton = ({
  contractId,
  amount,
  onCloseModal,
}: StripeQRButtonProps) => {
  const { mutateAsync, isPending } =
    useContractPendingDepositMutation(contractId);
  const { initialValues, resetForm } = useFormContext<DepositFormValues>();
  const { openModal } = useQRPayment();

  const handleStripeQRSubmit = async () =>
    mutateAsync({
      amount: Number(amount),
    }).then(({ depositId }) => {
      openModal({ id: depositId, isDeposit: true });
      resetForm(initialValues);
      onCloseModal();
    });

  return (
    <Button onClick={handleStripeQRSubmit} loading={isPending} emphasis="high">
      <FormattedMessage {...messages.seeQR} />
    </Button>
  );
};
