import {
  Contract,
  ContractPayment,
  SubscriptionAvailableInsurance,
  usePatchSubscriptionInsuranceMutation,
} from '@cooltra/station-based-api';
import { Button, Card } from '@cooltra/ui';
import { getSubscriptionInsuranceName } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Amount, ExtraAdded } from '~/common';
import { useNotification, useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export type SubscriptionInsuranceCardProps = {
  insurance: SubscriptionAvailableInsurance;
  contract: Contract;
  payments: ContractPayment[];
};

export const SubscriptionInsuranceCard = ({
  insurance,
  contract,
  payments,
}: SubscriptionInsuranceCardProps) => {
  const { contractId } = contract;
  const isAdded = contract.insurance === insurance.name;

  const { mutateAsync, isPending } =
    usePatchSubscriptionInsuranceMutation(contractId);

  const { addErrorNotification } = useNotification();

  const { hasDepositBeenCharged } = useSubscriptionContractFlags();

  const updateInsurance = () =>
    mutateAsync({ insuranceId: insurance.insuranceId }).catch(() =>
      addErrorNotification()
    );

  const canChangeInsurance = () => {
    if (contract.status === 'ACTIVE') {
      return false;
    }

    if (hasDepositBeenCharged) {
      return false;
    }

    console.log('payments', payments);

    const isInsurancePaidFor = payments
      .filter(({ status }) => status === 'PAID')
      .filter(({ insurance }) => insurance)
      .some(
        ({ insurance }) =>
          insurance.name === contract.insurance && insurance.price.value > 0
      );

    return !isInsurancePaidFor;
  };

  return (
    <Card
      data-testid={`INSURANCE_${insurance.name}`}
      className="bg-neutral-0 p-8"
    >
      <h2 className="mb-4 text-lg text-neutral-800">
        {getSubscriptionInsuranceName(insurance.name)}
      </h2>
      <div className="flex items-baseline gap-2 overflow-hidden">
        <span className="text-3xl font-semibold text-neutral-800">
          <Amount {...insurance.monthlyPrice} />
        </span>
        <span className="text-base font-medium text-neutral-600 truncate">
          <FormattedMessage {...messages.perMonth} />
        </span>
      </div>
      <div className="mt-4 flex items-center justify-end h-11">
        {isAdded ? (
          <ExtraAdded />
        ) : (
          <Button
            onClick={updateInsurance}
            loading={isPending}
            disabled={!canChangeInsurance()}
          >
            <FormattedMessage {...messages.add} />
          </Button>
        )}
      </div>
    </Card>
  );
};
