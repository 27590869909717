import { FormattedMessage } from 'react-intl';
import { ContractDeposit } from '@cooltra/station-based-api';

import { getContractDepositMethodMessage } from '~/utils/contract';

import { TextSeparator } from '../../../TextSeparator/TextSeparator';
import { DateTime } from '../../../DateTime';
import { Amount } from '../../../Amount/Amount';
import { DepositCard } from '../DepositCard';
import { DepositCardHeading } from '../DepositCardHeading/DepositCardHeading';

import messages from './messages';

export type ReleasedDepositProps = {
  deposit: ContractDeposit;
};

export const ReleasedDeposit = ({
  deposit: { amount, operationNumber, method, releasedAt, lastFourDigits },
}: ReleasedDepositProps) => (
  <DepositCard>
    <DepositCardHeading className="mb-6" />
    <div className="flex justify-between items-stretch">
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          <FormattedMessage {...messages.depositReleased} />
        </span>
        <div className="flex items-center gap-3 text-neutral-500">
          <DateTime
            date={releasedAt}
            dateClassName="text-neutral-500 text-base"
            timeClassName="text-neutral-500 text-base"
          />
          <TextSeparator />
          <FormattedMessage
            {...getContractDepositMethodMessage(method)}
            tagName="span"
          />
          {method === 'TPV' && (
            <>
              <TextSeparator />
              <span>#{operationNumber}</span>
              <TextSeparator />
              <span>#{lastFourDigits}</span>
            </>
          )}
        </div>
      </div>
      <div className="flex items-end">
        <Amount
          className="font-semibold text-xl text-neutral-800 grow"
          {...amount}
        />
      </div>
    </div>
  </DepositCard>
);
