import { FormInputField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFormValues } from '../../Form';

import messages from './messages';

export const CodePrefix = () => {
  const {
    values: { editMode },
  } = useFormContext<CooltraForEmployeesFormValues>();

  return (
    <FormInputField
      name="codePrefix"
      id="codePrefix"
      disabled={editMode}
      label={
        <InputLabel htmlFor="codePrefix">
          <FormattedMessage {...messages.codePrefix} />
        </InputLabel>
      }
    />
  );
};
