import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {
  DailyContractsListItem,
  useContractsByDayQuery,
} from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import { POSNavbar } from '../POSNavbar/POSNavbar';
import { useFilters } from '../useFilters';
import { SmallContractCard } from '../ContractCard';
import {
  ContractSummaryVehicleType,
  ContractsSummary,
} from '../ContractsSummary/ContractsSummary';
import { POSLayout } from '../POSLayout/POSLayout';
import { posDateFormat } from '../utils';

import { MonthlyCalendar } from './MonthlyCalendar';
import messages from './messages';

type ContractsByTime = {
  time: string;
} & DailyContractsListItem;

export const MonthlyView = () => {
  const { date, serviceType, servicePointId, status } = useFilters();
  const monthStart = startOfWeek(startOfMonth(date));
  const monthEnd = endOfWeek(endOfMonth(date));

  const { data, isLoading, isError } = useContractsByDayQuery({
    minDate: format(monthStart, posDateFormat),
    maxDate: format(monthEnd, posDateFormat),
    serviceType: serviceType || undefined,
    servicePoint: servicePointId,
    status: status,
  });

  if (isError) {
    return <ErrorPage />;
  }

  const vehicleTypes: ContractSummaryVehicleType[] = Object.values(data || {})
    .flatMap((contracts) => Object.values(contracts).flat())
    .filter((contract) => contract.vehicleType?.name)
    .map(({ serviceType, vehicleType }) => ({
      name: vehicleType?.name || '',
      type: serviceType,
    }));

  const renderContractCard = ({
    contractId,
    serviceType,
    status,
    contractNumber,
    accessories,
    time,
    hasClickAndRide,
    isSubscription,
  }: DailyContractsListItem & { time: string }) => (
    <li key={contractId}>
      <SmallContractCard
        hasClickAndRide={hasClickAndRide}
        contractId={contractId}
        serviceType={serviceType}
        status={status}
        accessories={accessories}
        isSubscription={isSubscription}
      >
        <span className="text-xs font-medium truncate">
          <span className="font-semibold">{time}</span>
          <span>{' - '}</span>
          <span>{contractNumber}</span>
        </span>
      </SmallContractCard>
    </li>
  );

  return (
    <POSLayout
      isLoading={isLoading}
      renderNavbar={<POSNavbar view="monthly" />}
      renderSummary={<ContractsSummary vehicleTypes={vehicleTypes} />}
      renderContent={
        <MonthlyCalendar
          displayDate={date}
          renderDay={(date) => {
            const day = format(date, posDateFormat);
            const contracts: ContractsByTime[] = Object.keys(data?.[day] ?? {})
              .map(
                (time) =>
                  data?.[day]?.[time]?.flatMap((contract) => ({
                    ...contract,
                    time,
                  })) ?? []
              )
              .flat();

            return (
              <div data-testid={day}>
                <ul className="flex flex-col gap-1 mb-1">
                  {contracts.slice(0, 3).map(renderContractCard)}
                </ul>
                {contracts.length > 3 && (
                  <Tooltip
                    interactive={true}
                    trigger="click"
                    content={
                      <ul
                        className="flex flex-col gap-1 p-5 w-48"
                        data-testid="CONTRACT_TOOLTIP"
                      >
                        {contracts.map(renderContractCard)}
                      </ul>
                    }
                    button={
                      <button className="link link-primary text-center text-xs w-full py-2">
                        <FormattedMessage
                          {...messages.more}
                          values={{ count: contracts.length - 3 }}
                        />
                      </button>
                    }
                  />
                )}
              </div>
            );
          }}
        />
      }
    />
  );
};
