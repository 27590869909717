import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdInfoOutline } from 'react-icons/md';

import { useSubscriptionContractFlags } from '~/hooks';

import messages from './messages';

export const ContractPendingSignAlert = () => {
  const {
    isSignedOrPendingSignature,
    isPendingSignature,
    isSignedAndAwaitingActivation,
  } = useSubscriptionContractFlags();

  if (!isSignedOrPendingSignature) {
    return null;
  }

  return (
    <Card className="p-6 mb-10 shadow bg-neutral-100/50">
      <div className="h-full flex gap-4">
        <div className="grow">
          <h6 className="text-lg text-neutral-800 font-semibold flex items-center gap-3">
            <MdInfoOutline className="text-2xl" />
            <FormattedMessage {...messages.alertTitle} />
          </h6>
          <ul className="list-disc pl-14">
            {isPendingSignature && (
              <li className="text-neutral-800">
                <FormattedMessage {...messages.shouldCancelSign} />
              </li>
            )}
            {isSignedAndAwaitingActivation && (
              <li className="text-neutral-800">
                <FormattedMessage {...messages.awaitingToBeActivated} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </Card>
  );
};
