import { MapLine } from '../../Map';

import { useShiftRouteCoordinates } from './shift-route-coordinates-provider';

export const RouteLine = () => {
  const { routeCoordinates } = useShiftRouteCoordinates();

  return (
    <MapLine
      sourceId="shift-source"
      layerId="vehicle-route"
      coordinates={routeCoordinates}
      paint={{
        'line-color': '#8A8A8A',
        'line-width': 3,
        'line-dasharray': [0.3, 2],
      }}
    />
  );
};
