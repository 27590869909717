import { FormattedMessage } from 'react-intl';
import { ContractDeposit, ContractStatus } from '@cooltra/station-based-api';

import {
  Amount,
  DateTime,
  ReleaseDeposit,
  StripeCustomerLink,
  TextSeparator,
} from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';
import { getContractDepositMethodMessage } from '~/utils/contract';

import messages from './messages';

export type ChargedDepositProps = {
  contractId: string;
  contractStatus: ContractStatus;
  deposit: ContractDeposit;
};

export const ChargedDeposit = ({
  contractId,
  contractStatus,
  deposit,
}: ChargedDepositProps) => {
  const { withheldAt, amount, method, paymentMethodDetails } = deposit;

  const { hasDepositBeenCharged, isSignedOrPendingSignature } =
    useSubscriptionContractFlags();

  const displayReleaseButton =
    hasDepositBeenCharged &&
    (contractStatus === 'DRAFT' ||
      contractStatus === 'BOOKED' ||
      contractStatus === 'ACTIVE' ||
      contractStatus === 'CLOSED');

  return (
    <div className="flex justify-between items-end">
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          <FormattedMessage {...messages.depositCharged} />
        </span>
        <div className="flex items-center gap-3 text-neutral-500">
          <DateTime
            date={withheldAt}
            dateClassName="text-neutral-500 text-base"
            timeClassName="text-neutral-500 text-base"
          />
          <TextSeparator />
          {method === 'BOOKING_CARD' && paymentMethodDetails && (
            <StripeCustomerLink paymentMethod={paymentMethodDetails} />
          )}
          {method !== 'BOOKING_CARD' && (
            <FormattedMessage
              {...getContractDepositMethodMessage(method)}
              tagName="span"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3 items-end">
        <div className="flex items-center gap-3">
          {displayReleaseButton && (
            <ReleaseDeposit
              deposit={deposit}
              contractId={contractId}
              disabled={isSignedOrPendingSignature}
            />
          )}
          <Amount
            className="font-semibold text-xl text-neutral-800"
            {...amount}
          />
        </div>
      </div>
    </div>
  );
};
