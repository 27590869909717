import { Contract } from '@cooltra/station-based-api';

import {
  ContractBadge,
  DiscardContractButton,
  SubscriptionContractState,
} from '~/common';

import { PendingAmount } from '../../../common/Contract/PendingAmount/PendingAmount';
import { CancelSubscriptionButton } from '../CancelSubscriptionButton/CancelSubscriptionButton';
import { NoShowSubscriptionButton } from '../NoShowSubscriptionButton/NoShowSubscriptionButton';
import { SignSubscriptionButton } from '../SignSuscriptionButton/SignSubscriptionButton';
import { SendContractByEmailButton } from '../SendContractByEmailButton/SendContractByEmailButton';
import { CloseSubscriptionButton } from '../CloseSubscriptionButton/CloseSubscriptionButton';

import { MonthlyCharge } from './MonthlyCharge/MonthlyCharge';
import { DepositAmount } from './DepositAmount/DepositAmount';

export type SubscriptionContractNavbarProps = {
  contract: Contract;
};

export const SubscriptionContractNavbar = ({
  contract,
}: SubscriptionContractNavbarProps) => {
  const { contractNumber, status, pendingAmount, monthlyCharge } = contract;

  return (
    <div
      data-testid="SUBSCRIPTION_CONTRACT_NAVBAR"
      className="bg-neutral-0 shadow-sm relative z-10 h-20 px-8"
    >
      <div className="flex flex-row justify-between items-center gap-x-4 h-full">
        <div className="h-full flex flex-row items-center justify-center gap-x-4 gap-y-4">
          <h2 className="font-semibold text-2xl text-neutral-700">
            {contractNumber}
          </h2>
          <div className="flex gap-x-4 items-center">
            <ContractBadge status={status} />
            <SubscriptionContractState compact={true} />
          </div>
        </div>
        {status === 'CANCELED' || status === 'NO_SHOW' ? null : (
          <div className="flex flex-col xl:flex-row xl:items-center justify-center gap-y-4 h-full gap-x-10">
            <div className="flex gap-x-4 order-1 xl:order-2">
              <NoShowSubscriptionButton contract={contract} />
              <CancelSubscriptionButton contract={contract} />
              <DiscardContractButton contract={contract} />
              <SignSubscriptionButton contract={contract} />
              <SendContractByEmailButton contract={contract} />
              <CloseSubscriptionButton contract={contract} />
            </div>
            <div className="flex gap-x-10 order-2 xl:order-1 justify-end">
              <MonthlyCharge amount={monthlyCharge} />
              <DepositAmount contract={contract} />
              <PendingAmount amount={pendingAmount} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
