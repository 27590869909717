import { Card, Placeholder } from '@cooltra/ui';

import { ServicePointsRowLayout } from './ServicePointsRowLayout';

export const ServicePointsCardLoading = () => {
  return (
    <Card>
      <ServicePointsRowLayout
        className="min-h-20"
        servicePoint={<Placeholder className="h-2 w-20" />}
      />
    </Card>
  );
};
