import { RentalReservation } from '@cooltra/api';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { RentalTimelineItemReduced } from '~/libs/reduce-rental-timeline';
import { calculateTimeDifference } from '~/libs/time-difference';

import { RentalTimelineDot } from '../RentalTimelineDot';
import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';

import messages from './messages';

export type ReservationItemProps = {
  reservation: RentalReservation;
  checkInEvent: RentalTimelineItemReduced;
};

export const ReservationItem = ({
  reservation,
  checkInEvent,
}: ReservationItemProps) => {
  const getDifferenceBetweenReservationAndCheckIn = () => {
    if (!checkInEvent) {
      return <></>;
    }

    const timeDifference = calculateTimeDifference(
      new Date(reservation.reservationCreatedAt),
      new Date(checkInEvent.occurredOn)
    );

    return (
      <div className="relative">
        <span className="absolute text-xs text-neutral-600 bg-neutral-0 -translate-x-1/2 whitespace-nowrap">
          {timeDifference}
        </span>
      </div>
    );
  };

  return (
    <div className="mb-8">
      <RentalTimelineRowLayout
        isFirst
        time={
          <span className="text-sm tabular-nums font-semibold text-neutral-800">
            <FormattedTime
              value={reservation.reservationCreatedAt}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        }
        timeline={<RentalTimelineDot />}
        content={
          <span className="text-sm font-semibold">
            <FormattedMessage {...messages.reservationMade} />
          </span>
        }
      />
      <RentalTimelineRowLayout
        timeline={getDifferenceBetweenReservationAndCheckIn()}
      />
    </div>
  );
};
