import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RouterSidebarNavLink } from '@cooltra/navigation';

import { SidebarLinkContent, SidebarHeading } from '~/common';

import messages from './messages';

export const Sidebar = () => (
  <div>
    <div className="w-72 pt-14 pb-5" data-testid="PRICE_LISTS_SIDEBAR">
      <div className="px-8 mt-14 mb-7">
        <SidebarHeading className="mb-4">
          <FormattedMessage {...messages.priceLists} />
        </SidebarHeading>
      </div>
      <ul>
        <li>
          <Divider />
          <RouterSidebarNavLink to="price-lists/web">
            <SidebarLinkContent>
              <FormattedMessage {...messages.web} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
        </li>
        <li>
          <Divider />
          <RouterSidebarNavLink to="price-lists/pos">
            <SidebarLinkContent>
              <FormattedMessage {...messages.pos} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
        </li>
        <li>
          <Divider />
          <RouterSidebarNavLink to="price-lists/deposits">
            <SidebarLinkContent>
              <FormattedMessage {...messages.deposits} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
      </ul>
    </div>
    <div className="w-72 pb-20" data-testid="STORE_MANAGEMENT_SIDEBAR">
      <div className="px-8 mt-14 mb-7">
        <SidebarHeading className="mb-4">
          <FormattedMessage {...messages.storeManagement} />
        </SidebarHeading>
      </div>
      <ul>
        <li>
          <Divider />
          <RouterSidebarNavLink to="service-points">
            <SidebarLinkContent>
              <FormattedMessage {...messages.servicePoints} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
        </li>
      </ul>
    </div>
  </div>
);
