import { FormattedMessage } from 'react-intl';
import { ContractListItem } from '@cooltra/station-based-api';
import { getVehicleName, vehicleImages, VehicleTypeName } from '@cooltra/utils';

import { ContractBadge, DateTime, SubscriptionContractState } from '~/common';

import { ContractCardRouterLink } from '../../../ContractCard';
import { ContractRowLayout } from '../ContractRowLayout/ContractRowLayout';

import messages from './messages';

export type UserCardProps = {
  contract: ContractListItem;
};

export const ContractCard = ({
  contract: {
    contractId,
    contractNumber,
    user,
    startDate,
    endDate,
    servicePoint,
    vehicleType,
    status,
    assignedVehicle,
    isSubscription,
  },
}: UserCardProps) => {
  const getCustomerSection = () => {
    if (!user) {
      return (
        <p className="text-neutral-300 text-sm">
          <FormattedMessage {...messages.missingUserData} />
        </p>
      );
    }

    return (
      <div className="flex flex-col gap-2">
        <p className="text-neutral-800 text-sm capitalize">
          {`${user.name} ${user.surname}`}
        </p>
        <p className="text-neutral-400 text-xs"> {user.phone} </p>
      </div>
    );
  };

  const getVehicleSection = () => {
    if (!vehicleType) {
      return (
        <p className="text-neutral-300 text-sm">
          <FormattedMessage {...messages.missingVehicleData} />
        </p>
      );
    }

    const vehicleName = getVehicleName(vehicleType.name as VehicleTypeName);

    return (
      <div className="flex gap-2 items-center">
        <img
          className="w-12"
          src={vehicleImages[vehicleType.name as VehicleTypeName]}
          alt={vehicleName}
        />
        <div className="flex flex-col gap-1">
          <p className="hidden lg:block text-sm text-neutral-600 font-medium">
            {vehicleName}
          </p>
          {assignedVehicle && (
            <p className="text-sm font-semibold text-neutral-800">
              {assignedVehicle.licensePlate}
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <ContractCardRouterLink id={contractId} isSubscription={isSubscription}>
      <ContractRowLayout
        className="min-h-28 py-4"
        contractNumber={
          <p className="m-0 text-neutral-600 font-semibold">
            #{contractNumber}
          </p>
        }
        customer={getCustomerSection()}
        date={
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <DateTime date={startDate} timeClassName="hidden" />
              {endDate && (
                <>
                  <span> - </span>
                  <DateTime date={endDate} timeClassName="hidden" />
                </>
              )}
            </div>
            <p className="text-xs text-neutral-400">{servicePoint.name}</p>
          </div>
        }
        vehicle={getVehicleSection()}
        status={<ContractBadge status={status} />}
        subscription={
          isSubscription ? <SubscriptionContractState compact={true} /> : null
        }
      />
    </ContractCardRouterLink>
  );
};
