import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import {
  ContractDeposit,
  useCancelContractDepositMutation,
} from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';
import { useQueryClient } from '@tanstack/react-query';

import { useNotification, usePolling } from '~/hooks';

import { Amount } from '../../../Amount/Amount';
import { ConfirmModal } from '../../../ConfirmModal/ConfirmModal';

import messages from './messages';

export type ReleaseDepositProps = {
  contractId: string;
  deposit: ContractDeposit;
  disabled?: boolean;
};

export const ReleaseDeposit = ({
  contractId,
  deposit: { amount, id, lastFourDigits, operationNumber, method, releasedAt },
  disabled = false,
}: ReleaseDepositProps) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { mutateAsync, isPending, isSuccess } =
    useCancelContractDepositMutation(contractId, id);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
    await queryClient.invalidateQueries({
      queryKey: ['contract-payments', contractId],
    });
  };

  const { isPolling } = usePolling({
    fn: invalidateQueries,
    onSettle: () => toggleOff(),
    interval: isSuccess && !releasedAt ? 500 : 0,
  });

  const handleReleaseDeposit = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.depositHasBeenReleased));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  const getReleaseContractDepositModal = () => {
    if (method === 'TPV') {
      return (
        <ConfirmModal
          className="max-w-md min-w-md"
          dataTestId="RELEASE_CONTRACT_DEPOSIT_CONFIRM_MODAL"
          isOpen={isOpen}
          title={formatMessage(messages.areYourSureToReleaseTPVDeposit)}
          content={
            <div className="mb-5">
              <FormattedMessage
                tagName="p"
                {...messages.tpvOperationId}
                values={{
                  operationId: operationNumber,
                }}
              />
              <FormattedMessage
                tagName="p"
                {...messages.cardLastFourDigits}
                values={{
                  lastFourDigits: lastFourDigits,
                }}
              />
            </div>
          }
          confirmText={formatMessage(messages.tpvConfirmRelease, {
            amount: <Amount {...amount} />,
          })}
          onClose={toggleOff}
          onConfirm={handleReleaseDeposit}
          loading={isPending || isPolling}
        />
      );
    }

    return (
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="RELEASE_CONTRACT_DEPOSIT_CONFIRM_MODAL"
        isOpen={isOpen}
        title={formatMessage(messages.areYourSureToReleaseStripeDeposit)}
        content=""
        confirmText={formatMessage(messages.confirmRelease, {
          amount: <Amount value={amount.value} currency={amount.currency} />,
        })}
        onClose={toggleOff}
        onConfirm={handleReleaseDeposit}
        loading={isPending || isPolling}
      />
    );
  };

  return (
    <>
      {getReleaseContractDepositModal()}
      <Button size="sm" onClick={toggleOn} disabled={disabled}>
        {method === 'TPV' ? (
          <FormattedMessage {...messages.markAsReleased} />
        ) : (
          <FormattedMessage {...messages.release} />
        )}
      </Button>
    </>
  );
};
