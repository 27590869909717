import { useServicePointsQuery } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';

import { ErrorPage, Four0Three } from '~/common';

import { ServicePoints } from './ServicePoints';

export const ServicePointList = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data, isLoading, isError, error } = useServicePointsQuery(
    { allServicePoints: true },
    { enabled: hasPermission('read:prices') }
  );

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const servicePoints =
    data === undefined ? [] : data.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <ServicePoints servicePoints={servicePoints} isLoading={isLoading} />
    </div>
  );
};
