import { InvoiceListItem, useUserInvoicesQuery } from '@cooltra/api';
import { List } from '@cooltra/ui';

import { ErrorPage } from '../../ErrorPage';
import { InvoiceCard, InvoiceCardLoading } from '../InvoiceCard';
import { InvoicesListHeader } from '../InvoicesListHeader/InvoicesListHeader';
import { NoInvoices } from '../NoInvoices/NoInvoices';

export type InvoicesProps = {
  userId: string;
};

export const Invoices = ({ userId }: InvoicesProps) => {
  const { data, isLoading, isError } = useUserInvoicesQuery(userId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_INVOICES" className="pt-10">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="invoiceId"
        data={data?.invoices || []}
        renderEmptyMessage={<NoInvoices />}
        renderHeader={<InvoicesListHeader />}
        renderLoadingRow={<InvoiceCardLoading />}
        renderRow={(invoice: InvoiceListItem) => (
          <InvoiceCard invoice={invoice} />
        )}
      />
    </div>
  );
};
