import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { ContractRowLayout } from '../ContractRowLayout/ContractRowLayout';

import messages from './messages';

export const ContractsListHeader = () => (
  <ContractRowLayout
    contractNumber={
      <ListColumnLabel>
        <FormattedMessage {...messages.contractNumber} />
      </ListColumnLabel>
    }
    customer={
      <ListColumnLabel>
        <FormattedMessage {...messages.customer} />
      </ListColumnLabel>
    }
    date={
      <ListColumnLabel>
        <FormattedMessage {...messages.date} />
      </ListColumnLabel>
    }
    vehicle={
      <ListColumnLabel>
        <FormattedMessage {...messages.vehicle} />
      </ListColumnLabel>
    }
    status={
      <ListColumnLabel>
        <FormattedMessage {...messages.status} />
      </ListColumnLabel>
    }
    subscription={null}
  />
);
