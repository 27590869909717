import { Card, Placeholder } from '@cooltra/ui';

import { ContractRowLayout } from '../ContractRowLayout/ContractRowLayout';

export const ContractCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <ContractRowLayout
      contractNumber={<Placeholder className="w-28 h-4" />}
      customer={
        <>
          <Placeholder className="h-3 w-20 mb-3" />
          <Placeholder className="h-2 w-24" />
        </>
      }
      date={
        <div>
          <Placeholder className="h-2 w-32 mb-3" />
          <Placeholder className="h-2 w-32" />
        </div>
      }
      vehicle={
        <div>
          <Placeholder className="h-5 w-20" />
        </div>
      }
      status={
        <div className="text-center">
          <Placeholder className="h-6 w-20 mx-auto rounded-full" />
        </div>
      }
      subscription={<Placeholder className="w-28 h-4" />}
    />
  </Card>
);
