import { defineMessages } from 'react-intl';

export default defineMessages({
  priceLists: {
    defaultMessage: 'Price lists',
  },
  web: {
    defaultMessage: 'Web',
  },
  pos: {
    defaultMessage: 'Point of sale',
  },
  deposits: {
    defaultMessage: 'Deposits',
  },
  storeManagement: {
    defaultMessage: 'Store management',
  },
  servicePoints: {
    defaultMessage: 'Service points',
  },
});
