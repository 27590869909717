import { DepositMethod } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';
import { MdBackupTable, MdCreditCard, MdQrCode, MdSend } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const methodIcons: Record<DepositMethod, ReactNode> = {
  TPV: <MdBackupTable className="text-2xl" />,
  BOOKING_CARD: <MdCreditCard className="text-2xl" />,
  STRIPE_QR: <MdQrCode className="text-2xl" />,
  EMAIL: <MdSend className="text-2xl" />,
};

export type DepositMethodOptionProps = {
  type: DepositMethod;
  checked: boolean;
};

export const DepositMethodOption = ({
  type,
  checked,
}: DepositMethodOptionProps) => (
  <div
    className={classNames(
      'p-6 flex gap-2 w-full font-semibold transition-colors',
      checked && 'text-primary-500'
    )}
  >
    {methodIcons[type]}
    <FormattedMessage {...messages[type]} tagName="span" />
  </div>
);
