import { RouterCardLink } from '@cooltra/navigation';
import { ServicePoint } from '@cooltra/station-based-api';
import { MdOutlineLocationOn, MdStorefront } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

import { ServicePointsRowLayout } from './ServicePointsRowLayout';

export const ServicePointsCard = ({ id, name, type }: ServicePoint) =>
  (
    <RouterCardLink
      to={`station-based/service-points/${id}`}
      data-testid={id}
    >
      <ServicePointsRowLayout
        className="min-h-20"
        servicePoint={
          <div className={classNames('w-full flex items-center gap-4 pr-4')}>
            <Icon data-testid={`ICON_${type}`} className="text-xl">
              {type === 'SHOP' ? <MdStorefront /> : <MdOutlineLocationOn />}
            </Icon>
            <span className="text-center">{name}</span>
          </div>
        }
      />
    </RouterCardLink>
  );
