import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';

import {
  ManualAdjustmentButton,
  ManualAdjustmentForm,
} from '../../ManualAdjustmentForm';
import { PaySubscriptionTicket } from '../PaySubscriptionTicket/PaySubscriptionTicket';

export type SubscriptionContractPaymentsButtonsProps = {
  hasPendingPayments: boolean;
  contract: Contract;
  payment?: ContractPayment;
  disabled?: boolean;
};

export const SubscriptionContractPaymentsButtons = ({
  contract,
  payment,
  hasPendingPayments,
  disabled = false,
}: SubscriptionContractPaymentsButtonsProps) => {
  const [isManualAdjustmentOpen, { toggleOff, toggleOn }] = useToggle();

  const enabled = !isManualAdjustmentOpen;

  return (
    <div className="justify-end flex flex-col gap-8 relative items-end">
      {isManualAdjustmentOpen && <ManualAdjustmentForm close={toggleOff} />}
      <div className="flex flex-row gap-2">
        <ManualAdjustmentButton
          disabled={disabled || !enabled}
          onClick={toggleOn}
        />
        <PaySubscriptionTicket
          contract={contract}
          payment={payment}
          disabled={disabled || !hasPendingPayments}
        />
      </div>
    </div>
  );
};
