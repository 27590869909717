import { createContext } from 'react';

export type OpenModalOptions = {
  id: string;
  isDeposit: boolean;
};

export type QRPaymentContextShape = {
  openModal: (options: OpenModalOptions) => void;
};

export const QRPaymentContext = createContext<
  QRPaymentContextShape | undefined
>(undefined);
