import { FormattedMessage } from 'react-intl';
import { Contract, useContractPaymentsQuery } from '@cooltra/station-based-api';

import { QRPaymentProvider } from '~/libs/qr-payment';

import { SubscriptionContractPendingPayments } from '../SubscriptionContractPendingPayments/SubscriptionContractPendingPayments';
import { SubscriptionPastCharges } from '../../SubscriptionPastCharges/SubscriptionPastCharges';
import { SubscriptionContractDeposit } from '../SubscriptionContractDeposit/SubscriptionContractDeposit';
import { ContractPendingSignAlert } from '../../ContractPendingSignAlert/ContractPendingSignAlert';

import messages from './messages';

export type SubscriptionContractPaymentsProps = {
  contract: Contract;
};

export const SubscriptionContractPayments = ({
  contract,
}: SubscriptionContractPaymentsProps) => {
  const { contractId } = contract;
  const { data: payments = [] } = useContractPaymentsQuery(contractId, {
    enabled: !!contractId,
  });

  return (
    <QRPaymentProvider>
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <ContractPendingSignAlert />
        <h1 className="text-xl text-neutral-700 mb-4">
          <FormattedMessage {...messages.payments} />
        </h1>
        <SubscriptionContractDeposit contract={contract} />
        <SubscriptionContractPendingPayments
          contract={contract}
          payments={payments}
        />
        <SubscriptionPastCharges contract={contract} payments={payments} />
      </div>
    </QRPaymentProvider>
  );
};
