import { capitalize } from '@cooltra/utils';
import { Link } from '@cooltra/ui';
import { PaymentMethodDetails } from '@cooltra/station-based-api';

import { getEnvVariable } from '~/utils/environment';

export type StripeCustomerLinkProps = {
  paymentMethod: PaymentMethodDetails;
};

const getStripeCustomerLink = (stripeCustomerId: string) => {
  const accountPath = '/acct_1ArSXbLD1f1asXZR';
  const testPath =
    getEnvVariable('ENVIRONMENT') === 'production' ? '' : '/test';
  return `https://dashboard.stripe.com${accountPath}${testPath}/customers/${stripeCustomerId}`;
};

export const StripeCustomerLink = ({
  paymentMethod,
}: StripeCustomerLinkProps) => (
  <Link href={getStripeCustomerLink(paymentMethod.customerId)} target="_blank">
    <span>
      {capitalize(paymentMethod.brand).concat(
        ' ···· ',
        paymentMethod.lastFourDigits
      )}
    </span>
  </Link>
);
