import { Card, CardProps } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export const DepositCard = ({ className, ...rest }: CardProps) => (
  <Card
    className={classNames('py-8 px-14 mb-6', className)}
    data-testid="CONTRACT_DEPOSITS"
    {...rest}
  />
);
