import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  ContractStatus,
  useInvoicePdfQuery,
} from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';
import { MdChevronRight } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';
import { Button, Card } from '@cooltra/ui';
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { getErrorStatus } from '@cooltra/axios';

import { useNotification } from '~/hooks';
import { StripeCustomerLink } from '~/common';

import { TextSeparator } from '../../../TextSeparator/TextSeparator';
import { DateTime } from '../../../DateTime';
import { Amount } from '../../../Amount/Amount';
import { ContractPaymentItems } from '../../ContractPaymentsItems';
import { RefundButton } from '../RefundButton/RefundButton';

import messages, {
  contractPaymentMessages,
  contractPaymentTitleMessages,
} from './messages';

export type PaidTicketProps = {
  contractId: string;
  contractStatus: ContractStatus;
  payment: ContractPayment;
  refundDisabled?: boolean;
};

export const PaidTicket = ({
  contractId,
  contractStatus,
  payment,
  refundDisabled = false,
}: PaidTicketProps) => {
  const {
    paidAt,
    paymentMethod,
    status,
    totalPrice: { currency, value },
    invoiceId,
    creditNoteId,
    paymentMethodDetails,
  } = payment;

  const [isExpanded, { toggle }] = useToggle();
  const { addErrorNotification } = useNotification();

  const { formatMessage } = useIntl();
  const {
    fetchStatus: invoiceFetchStatus,
    refetch: invoiceRefetch,
    error: invoiceError,
  } = useInvoicePdfQuery(invoiceId || '', {
    enabled: false,
  });
  const {
    fetchStatus: creditNoteFetchStatus,
    refetch: creditNoteRefetch,
    error: creditNoteError,
  } = useInvoicePdfQuery(creditNoteId || '', {
    enabled: false,
  });

  const checkError = useCallback(
    (error: AxiosError) => {
      const errorMessage =
        getErrorStatus(error) === 404
          ? formatMessage(messages.pdfDoesNotExist)
          : formatMessage(messages.somethingWentWrongOnDownloadingPdf);
      addErrorNotification(errorMessage);
    },
    [addErrorNotification, formatMessage]
  );

  useEffect(() => {
    if (invoiceError) {
      checkError(invoiceError);
    }
  }, [addErrorNotification, invoiceError, formatMessage, checkError]);

  useEffect(() => {
    if (creditNoteError) {
      checkError(creditNoteError);
    }
  }, [addErrorNotification, checkError, creditNoteError, formatMessage]);

  return (
    <Card className="p-8">
      <div
        className={classNames(
          'flex group items-center justify-between',
          isExpanded && 'is-expanded'
        )}
      >
        <div>
          <h2 className="text-lg text-neutral-800 mb-2">
            <FormattedMessage
              {...contractPaymentTitleMessages[paymentMethod!]}
            />
          </h2>
          <div className="flex items-center gap-3 text-neutral-500">
            <DateTime
              date={paidAt}
              dateClassName="text-neutral-500 text-base"
              timeClassName="text-neutral-500 text-base"
            />
            <TextSeparator />
            {paymentMethod === 'CARD' && paymentMethodDetails !== null ? (
              <StripeCustomerLink paymentMethod={paymentMethodDetails} />
            ) : (
              <FormattedMessage
                tagName="span"
                {...contractPaymentMessages[paymentMethod]}
              />
            )}
            {invoiceId && (
              <Button
                size="sm"
                loading={invoiceFetchStatus === 'fetching'}
                onClick={() => invoiceRefetch()}
              >
                <FormattedMessage {...messages.invoicePdf} />
              </Button>
            )}
            {creditNoteId && (
              <Button
                size="sm"
                loading={creditNoteFetchStatus === 'fetching'}
                onClick={() => creditNoteRefetch()}
              >
                <FormattedMessage {...messages.creditNotePdf} />
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-center gap-5">
          <RefundButton
            disabled={refundDisabled}
            contractId={contractId}
            contractStatus={contractStatus}
            payment={payment}
          />
          <Amount
            value={value}
            currency={currency}
            className={classNames(
              'text-xl font-semibold text-neutral-800',
              status === 'REFUNDED' ? 'line-through' : ''
            )}
          />
          <button
            aria-label={formatMessage(messages.expandSummary)}
            className="rotate-90 transition duration-150 group-[.is-expanded]:rotate-270"
            onClick={toggle}
          >
            <MdChevronRight className="text-3xl text-neutral-800" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="mt-8">
          <ContractPaymentItems.Vehicle className="mb-2" payment={payment} />
          <ContractPaymentItems.Coverage
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Accessories
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Penalties
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.ValueAdjustment
            contractId={contractId}
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Deposit
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Total
            className="mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
        </div>
      )}
    </Card>
  );
};
