import { useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFormValues } from '../../Form';
import { FormNumberInputField } from '../../../../FormattedInput';

import messages from './messages';

export const NumberOfCodes = () => {
  const {
    values: { editMode },
  } = useFormContext<CooltraForEmployeesFormValues>();

  return (
    <FormNumberInputField
      name="numberOfCodes"
      id="numberOfCodes"
      disabled={editMode}
      label={
        <InputLabel htmlFor="numberOfCodes">
          <FormattedMessage {...messages.numberOfCodes} />
        </InputLabel>
      }
    />
  );
};
